import Gender from "@/models/gender";
import CookieHelper from "../helpers/getCookieHelper";

interface ApiResponse<T> {
  response: T | null;
  error: string | null;
}

const configController = {
  getAllGenders: function (): Promise<ApiResponse<Gender[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}config/genders`, {
        method: 'GET',
        headers,
      })
        .then(response => response.json())
        .then(json => {
          if (json.response && Array.isArray(json.response)) {
            const genders = json.response.map((item: any) => new Gender(item));
            resolve({ response: genders, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected response format" });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },
};

export default configController;