"use client";

import React from "react";
import { Tab, Tabs, } from "@nextui-org/react";
import GenderConfig from "./GenderConfig";
import ComingSoon from "./ComingSoon";
import SupportConfig from "./SupportConfig";

export default function Config() {

  return (
    <div className="w-full h-full flex-1 p-4" style={{ overflowY: 'auto' }}>
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Config</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500">
        Verwaltere kleinere, datenbankgesteuerte, Plattforminhalte.
      </h2>
      <Tabs
        fullWidth
        radius="full"
        classNames={{
          base: "mt-6",
          cursor: "bg-content1 dark:bg-content1",
          panel: "w-full p-0 pt-4",
        }}
      >
        <Tab key="genders" title="Geschlechter">
          <GenderConfig />
        </Tab>
        <Tab key="support" title="Supportinformationen">
          <SupportConfig />
        </Tab>
        <Tab key="two" title="Coming Soon">
          <ComingSoon />
        </Tab>
      </Tabs>
    </div>
  );
}