import CookieHelper from "../helpers/getCookieHelper";
import AdminUser from "../models/adminUser";
import userService from "../services/userService";

interface ApiResponse<T> {
    token?: string | null;
    user?: AdminUser | null;
    error: string | null;
}

const authController = {
    loginUserApi: function (email: string, password: string, rememberEmail: boolean): Promise<ApiResponse<null>> {
        const data = { email, password };

        return new Promise((resolve) => {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;

            if (!apiUrl || !bearerToken) {
                resolve({ token: null, error: "Missing API configuration." });
                return;
            }

            fetch(`${apiUrl}login`, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json',
                    'bearer': bearerToken,
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.response && json.response.token) {
                        document.cookie = `happyActiveUser=${json.response.token}; path=/; Secure;`;

                        if (rememberEmail) {
                            localStorage.setItem('rememberedEmail', email);
                        } else {
                            localStorage.removeItem('rememberedEmail');
                        }

                        if (json.response.user) {
                            const userModel = AdminUser.fromJson(json.response.user);
                            userService.setUser(userModel);
                        }
                        resolve({ token: json.response.token, error: null });
                    } else {
                        resolve({ token: null, error: json.error || "Invalid login response." });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    resolve({ token: null, error: error.toString() });
                });
        });
    },

    presetEmailIfRemembered: function (): string {
        return localStorage.getItem('rememberedEmail') || '';
    },

    logoutUser: function (): void {
        userService.setUser(null);
        document.cookie = 'happyActiveUser=; path=/; Secure;';
    },

    refreshUser: function (): Promise<ApiResponse<AdminUser | null>> {
        return new Promise((resolve) => {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
            const token = CookieHelper.get('happyActiveUser');

            if (!apiUrl || !bearerToken) {
                resolve({ user: null, error: "Missing API configuration." });
                return;
            }

            fetch(`${apiUrl}refresh`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'bearer': bearerToken,
                    'usertoken': token || '',
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.response) {
                        const userModel = AdminUser.fromJson(json.response);
                        userService.setUser(userModel);
                        resolve({ user: userModel, error: null });
                    } else {
                        resolve({ user: null, error: json.error || "Invalid refresh response." });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    resolve({ user: null, error: error.toString() });
                });
        });
    },

    updateProfile: function (formData: FormData): Promise<ApiResponse<AdminUser | null>> {
        const apiUrl = process.env.REACT_APP_API_BASE_URL;
        const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
        const token = CookieHelper.get('happyActiveUser');

        return new Promise((resolve) => {
            if (!apiUrl || !bearerToken) {
                resolve({ user: null, error: "Missing API configuration." });
                return;
            }

            fetch(`${apiUrl}update`, {
                method: 'POST',
                body: formData,
                headers: {
                    'bearer': bearerToken,
                    'usertoken': token || '',
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json.response) {
                        const userModel = AdminUser.fromJson(json.response);
                        userService.setUser(userModel);
                        resolve({ user: userModel, error: null });
                    } else {
                        resolve({ user: null, error: json.error || "Unexpected error occurred" });
                    }
                })
                .catch((error) => {
                    console.error(error);
                    resolve({ user: null, error: error.toString() });
                });
        });
    }

};

export default authController;