import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const FilterEditor = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return (
    <div>
      <h2>Filter Editor</h2>
      {id ? (
        <p>Displaying editor for filter ID: {id}</p>
      ) : (
        <p>No filter ID provided.</p>
      )}
    </div>
  );
};

export default FilterEditor;
