import React, { useState, useEffect, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import CookieHelper from '../helpers/getCookieHelper';

interface MiddlewareProps {
  children: ReactNode;
}

const AntiAuthMiddleware: React.FC<MiddlewareProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    const token = CookieHelper.get('happyActiveUser');

    if (!token) {
      setIsAuthenticated(false);
      return;
    }

    fetch(`${process.env.REACT_APP_API_BASE_URL}tokenCheck`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'bearer': process.env.REACT_APP_API_BEARER_TOKEN || '',
        'usertoken': token,
      },
    })
      .then(response => response.json())
      .then(json => {
        setIsAuthenticated(!!json.response);
      })
      .catch(error => {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
      });
  }, []);

  if (isAuthenticated === null) {
    return null;
  }

  return isAuthenticated ? <Navigate to="/" /> : <>{children}</>;
};

export default AntiAuthMiddleware;