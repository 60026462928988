interface AppUserParams {
    id: string;
    username: string;
    displayname: string;
    gender?: string | null;
    profilePicture?: string | null;
    birthdate?: string | null;
    vipScore: number;
    vipScoreValidUntil?: string | null;
    activeSeller: boolean;
    createdAt?: string | null;
    publicUpdatedAt?: string | null;
    firstname?: string | null;
    lastname?: string | null;
  }
  
  class AppUser {
    id: string;
    username: string;
    displayname: string;
    gender: string | null;
    profilePicture: string | null;
    birthdate: Date | null;
    vipScore: number;
    vipScoreValidUntil: Date | null;
    activeSeller: boolean;
    createdAt: string | null;
    publicUpdatedAt: string | null;
    firstname: string | null;
    lastname: string | null;
  
    constructor({
      id,
      username,
      displayname,
      gender = null,
      profilePicture = null,
      birthdate = null,
      vipScore,
      vipScoreValidUntil = null,
      activeSeller,
      createdAt,
      publicUpdatedAt,
      firstname = null,
      lastname = null,
    }: AppUserParams) {
      this.id = id;
      this.username = username;
      this.displayname = displayname;
      this.gender = gender;
      this.profilePicture = profilePicture;
      this.birthdate = birthdate ? new Date(birthdate) : null;
      this.vipScore = vipScore;
      this.vipScoreValidUntil = vipScoreValidUntil ? new Date(vipScoreValidUntil) : null;
      this.activeSeller = activeSeller;
      this.createdAt = createdAt ? new Date(createdAt).toISOString() : null;
      this.publicUpdatedAt = publicUpdatedAt ? new Date(publicUpdatedAt).toISOString() : null;
      this.firstname = firstname;
      this.lastname = lastname;
    }
  
    static fromJson(json: AppUserParams): AppUser {
      return new AppUser(json);
    }
  }
  
  export default AppUser;  