import AdminUser from "../models/adminUser";

class UserService {
  private user: AdminUser | null;

  constructor() {
    const storedUser = localStorage.getItem('user');
    this.user = storedUser ? AdminUser.fromJson(JSON.parse(storedUser)) : null;
  }

  public get User(): AdminUser | null {
    return this.user;
  }

  setUser(user: AdminUser | null): void {
    this.user = user;

    if (user !== null) {
      console.log(user);
      localStorage.setItem('user', JSON.stringify(user));
    } else {
      localStorage.removeItem('user');
    }
  }

  getUser(): AdminUser | null {
    return this.user;
  }
}

const userService = new UserService();

export default userService;