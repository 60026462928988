import React from 'react';

const Offers = () => {
    return (
        <div className="w-full flex-1">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Angebotsverwaltung</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Verwalte aktive Community-Angebote.
            </h2>
        </div>
    );
};

export default Offers;
