import Filter from "./filter";

interface GenderParams {
  id: number;
  name: string;
  emoji: string;
  description: string;
  recommended_filters?: Filter[];
  createdAt: string;
  updatedAt: string;
}

class Gender {
  id: number;
  name: string;
  emoji: string;
  description: string;
  recommended_filters: Filter[];
  createdAt: string;
  updatedAt: string;

  constructor({
    id,
    name,
    emoji,
    description,
    recommended_filters = [],
    createdAt,
    updatedAt,
  }: GenderParams) {
    this.id = id;
    this.name = name;
    this.emoji = emoji;
    this.description = description;
    this.recommended_filters = recommended_filters;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  static fromJson(json: GenderParams): Gender {
    return new Gender({
      ...json,
      recommended_filters: json.recommended_filters?.map(filterJson => new Filter(filterJson)) || [],
    });
  }
}

export default Gender;