import React from "react";
import { Breadcrumbs, BreadcrumbItem } from "@nextui-org/react";
import { Icon } from "@iconify/react/dist/iconify.js";

interface BreadcrumbProps {
    path: string;
    onNavigate: (path: string) => void;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ path, onNavigate }) => {
    const pathArray = path.split('/').filter(p => p);

    return (
        <Breadcrumbs variant="solid" size="lg"
            itemClasses={{
                item: "px-2",
                separator: "px-0",
            }}
        >
            {pathArray.map((folder, index) => (
                <BreadcrumbItem
                    key={index}
                    onClick={() => onNavigate(pathArray.slice(0, index + 1).join('/'))}
                    startContent={index === 0 ? <Icon
                        className="mr-2"
                        height={18}
                        icon="solar:home-2-outline"
                        width={18}
                    /> : null}
                >
                    {index === 0 ? 'Home' : folder}
                </BreadcrumbItem>
            ))}
        </Breadcrumbs>
    );
};

export default Breadcrumb;