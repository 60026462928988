const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()])[A-Za-z\d@$!%*?&()]{8,}$/;

const PasswordHelper = {
    get: function (): string {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()";
        let password = "";

        const generatePassword = () => {
            password = "";
            for (let i = 0; i < 12; i++) {
                const randomIndex = Math.floor(Math.random() * charset.length);
                password += charset[randomIndex];
            }
        };

        generatePassword();
        while (!passwordRegex.test(password)) {
            generatePassword();
        }

        return password;
    }
};

export default PasswordHelper;
