import React from 'react';

const Dashboard = () => {
    return (
        <div>
            <h1 className='text-lg font-bold'>Dashboard</h1>
            <p>Das Wichtigste auf einen Blick.</p>
        </div>
    );
};

export default Dashboard;
