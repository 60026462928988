import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    useDisclosure,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Spinner,
    Spacer,
    Autocomplete,
    AutocompleteItem,
    Textarea,
    Tooltip,
    RadioGroup,
    Radio,
} from "@nextui-org/react";
import { Icon } from '@iconify/react/dist/iconify.js';
import filterController from '@/api/filterController';
import { FilterType, getFilterTypeLabel } from '@/models/filter';
import useToast from '@/hooks/useToast';

const INITIAL_VISIBLE_COLUMNS = ["name", "type", "actions"];

const adaptFilterData = (filter) => ({
    id: filter.id,
    name: filter.name,
    description: filter.description,
    type: filter.type,
    created: filter.createdAt,
    updated: filter.updatedAt,
});

const FilterList = () => {
    const { showToast } = useToast();
    const { isOpen: isCreateFilterModalOpen, onOpen: onCreateFilterModalOpen, onClose: onCreateFilterModalClose } = useDisclosure();
    const [isCreatingFilter, setIsCreatingFilter] = useState(false);

    const [filterName, setFilterName] = useState('');
    const [filterType, setFilterType] = useState('boolean');
    const [filterDesc, setFilterDesc] = useState('');

    const [filterNameError, setFilterNameError] = useState(false);
    const [filterDescError, setFilterDescError] = useState(false);

    const [valueDescPairs, setValueDescPairs] = useState([
        { value: '', description: '' },
        { value: '', description: '' },
    ]);

    const addPair = () => {
        setValueDescPairs([...valueDescPairs, { value: '', description: '' }]);
    };

    const removePair = (index) => {
        if (valueDescPairs.length > 2) {
            const newPairs = [...valueDescPairs];
            newPairs.splice(index, 1);
            setValueDescPairs(newPairs);
        }
    };

    const handlePairChange = (index, field, value) => {
        const newPairs = [...valueDescPairs];
        newPairs[index][field] = value;
        setValueDescPairs(newPairs);
    };

    const validateForm = () => {
        let valid = true;

        if (filterName.length < 3) {
            showToast("Titel muss mindestens 3 Zeichen lang sein.", 'error');
            setFilterNameError(true);
            valid = false;
        }

        if (filterDesc.length < 5) {
            showToast("Filterbeschreibung muss mindestens 5 Zeichen enthalten.", 'error');
            setFilterDescError(true);
            valid = false;
        }

        return valid;
    };

    const createFilter = async () => {
        setIsCreatingFilter(true);
        if (validateForm()) {
            const filterData = {
                name: filterName,
                description: filterDesc,
                filter_type: filterType,
                options: (filterType === FilterType.SINGLE_CHOICE || filterType === FilterType.MULTIPLE_CHOICE) ? valueDescPairs : [],
            };

            try {
                const { response, error } = await filterController.createFilter(filterData);
                if (error) {
                    console.error("Error creating filter:", error);
                    showToast(error, 'error');
                } else if (response === true) {
                    showToast("Filter erstellt!", 'success');
                    onCreateFilterModalClose();
                    fetchFilters();
                    setFilterName('');
                    setFilterDesc('');
                    setFilterType('boolean');
                    setValueDescPairs([
                        { value: '', description: '' },
                        { value: '', description: '' },
                    ]);
                } else {
                    showToast("Unexpected response received.", 'error');
                    console.error("Unexpected response received:", response);
                }
            } catch (error) {
                showToast(error, 'error');
                console.error("Error creating category:", error);
            } finally {
                setIsCreatingFilter(false);
            }
        } else {
            setIsCreatingFilter(false);
        }
    };

    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState([]);

    const fetchFilters = async () => {
        setLoading(true);
        try {
            const { response, error } = await filterController.getAllFilters();
            if (error) {
                console.error(error);
            } else {
                setFilters(response.map(adaptFilterData));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilters();
    }, []);

    const [filterValue, setFilterValue] = useState("");
    const [selectedKeys, setSelectedKeys] = useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [sortDescriptor, setSortDescriptor] = useState({
        column: "id",
        direction: "ascending",
    });

    const hasSearchFilter = Boolean(filterValue);
    const headerColumns = useMemo(() => {
        if (visibleColumns === "all") return columns;
        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);
    const filteredItems = useMemo(() => {
        let filteredFilters = [...filters];

        if (hasSearchFilter) {
            filteredFilters = filteredFilters.filter((filter) =>
                filter.name.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        return filteredFilters;
    }, [filters, filterValue, hasSearchFilter]);
    const items = useMemo(() => {
        return filteredItems.slice();
    }, [filteredItems]);
    const sortedItems = useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);

    const deleteFilter = useCallback(async (filterId) => {
        if (window.confirm("Möchtest Du diesen Filter wirklich löschen?")) {
            const { response, error } = await filterController.deleteFilter(filterId);
            if (error) {
                showToast(error, 'error');
                console.error("Error deleting filter:", error);
            } else {
                showToast("Erfolgreich gelöscht!", 'success');
                console.log("Filter deleted successfully:", response);
                fetchFilters();
            }
        }
    }, [showToast]);

    const renderCell = useCallback((filter, columnKey) => {
        const cellValue = filter[columnKey];
        switch (columnKey) {
            case "actions":
                return (
                    <div className="relative flex justify-end items-center gap-2">
                        <Dropdown className="w-[250px]">
                            <DropdownTrigger>
                                <Button isIconOnly size="sm" variant="light">
                                    <Icon
                                        className='text-default-300'
                                        height={18}
                                        icon="solar:menu-dots-bold"
                                        width={18}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem
                                    description="Daten & Filter"
                                    startContent={<Icon
                                        height={18}
                                        icon="solar:pen-2-outline"
                                        width={18}
                                    />}
                                    onClick={() => console.log("Edit filter", filter.id)}
                                >
                                    Bearbeiten
                                </DropdownItem>
                                <DropdownItem
                                    description="Unwiederruflich"
                                    startContent={<Icon
                                        height={18}
                                        icon="solar:trash-bin-minimalistic-outline"
                                        width={18}
                                    />}
                                    onClick={() => deleteFilter(filter.id)}
                                >
                                    Löschen
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            case "type":
                return getFilterTypeLabel(cellValue);
            default:
                return cellValue;
        }
    }, [deleteFilter]);


    const onSearchChange = useCallback((value) => {
        if (value) {
            setFilterValue(value);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = useCallback(() => {
        setFilterValue("")
    }, [])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        variant="bordered"
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Suche nach Titel..."
                        startContent={<Icon
                            className="text-default-500"
                            height={18}
                            icon="solar:magnifer-outline"
                            width={18}
                        />}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<Icon
                                    className="text-small"
                                    height={18}
                                    icon="solar:alt-arrow-down-outline"
                                    width={18}
                                />} variant="flat">
                                    Felder
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid}>
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button color="primary" onClick={onCreateFilterModalOpen}>
                            Filter erstellen
                        </Button>
                    </div>
                </div>
            </div>
        );
    }, [
        filterValue,
        visibleColumns,
        onSearchChange,
        onClear,
        onCreateFilterModalOpen,
    ]);

    const renderRows = useCallback((filters) => {
        const rows = [];

        const renderFilter = (filter, level = 0) => {
            rows.push(
                <TableRow key={filter.id} style={{ paddingLeft: `${level * 20}px` }}>
                    {headerColumns.map((column) => (
                        <TableCell key={column.uid}>{renderCell(filter, column.uid)}</TableCell>
                    ))}
                </TableRow>
            );
        };

        filters.forEach((filter) => {
            if (!filter.filter_id) {
                renderFilter(filter);
            }
        });

        return rows;
    }, [headerColumns, renderCell]);

    return (
        <>
            <div className="w-full flex-1">
                <div className="flex items-center gap-x-3">
                    <h1 className="text-3xl font-bold leading-9 text-default-foreground">Filterverwaltung</h1>
                </div>
                <h2 className="mt-2 text-small text-default-500 mb-6">
                    Bearbeite und erstelle Kategorie- und Nutzerfilter.
                </h2>
                {loading ? (
                    <Spinner />
                ) : (
                    <Table
                        aria-label="Filters Table"
                        isHeaderSticky
                        bottomContentPlacement="outside"
                        selectedKeys={selectedKeys}
                        selectionMode="none"
                        sortDescriptor={sortDescriptor}
                        topContent={topContent}
                        removeWrapper
                        topContentPlacement="outside"
                        onSelectionChange={setSelectedKeys}
                        onSortChange={setSortDescriptor}
                    >
                        <TableHeader columns={headerColumns}>
                            {(column) => (
                                <TableColumn
                                    key={column.uid}
                                    align={column.uid === "actions" ? "center" : "start"}
                                    allowsSorting={column.sortable}
                                >
                                    {column.name}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody emptyContent={"Keine Filter gefunden"} items={sortedItems}>
                            {renderRows(sortedItems)}
                        </TableBody>
                    </Table>
                )}
            </div>
            <Modal backdrop="blur" isOpen={isCreateFilterModalOpen} onClose={onCreateFilterModalClose} placement="bottom-center" size='5xl' isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Filter erstellen</ModalHeader>
                            <ModalBody>
                                <Input
                                    variant="bordered"
                                    type="text"
                                    size='md'
                                    label="Titel"
                                    value={filterName}
                                    defaultValue=""
                                    labelPlacement="inside"
                                    isInvalid={filterNameError}
                                    startContent={
                                        <Icon
                                            className="text-default-400 pointer-events-none"
                                            height={18}
                                            icon="solar:text-square-outline"
                                            width={18}
                                        />
                                    }
                                    onChange={(e) => {
                                        setFilterName(e.target.value);
                                        setFilterNameError(false);
                                    }}
                                />
                                <Textarea
                                    variant="bordered"
                                    type="text"
                                    size='md'
                                    label="Beschreibung"
                                    value={filterDesc}
                                    defaultValue=""
                                    labelPlacement="inside"
                                    isInvalid={filterDescError}
                                    startContent={
                                        <Icon
                                            className="text-default-400 pointer-events-none"
                                            height={18}
                                            icon="solar:document-text-outline"
                                            width={18}
                                        />
                                    }
                                    onChange={(e) => {
                                        setFilterDesc(e.target.value);
                                        setFilterDescError(false);
                                    }}
                                />
                                <RadioGroup
                                    value={filterType}
                                    onChange={(e) => setFilterType(e.target.value)}
                                    orientation="horizontal"
                                >
                                    {Object.values(FilterType).map((type) => (
                                        <Radio key={type} value={type}>
                                            {getFilterTypeLabel(type)}
                                        </Radio>
                                    ))}
                                </RadioGroup>
                                {(filterType === FilterType.SINGLE_CHOICE || filterType === FilterType.MULTIPLE_CHOICE) && (
                                    <>
                                        {valueDescPairs.map((pair, index) => (
                                            <div key={index} className="flex gap-3 items-center">
                                                <Input
                                                    variant="bordered"
                                                    type="text"
                                                    value={pair.value}
                                                    label={`Wert ${index + 1}`}
                                                    startContent={
                                                        <Icon
                                                            className="text-default-400 pointer-events-none"
                                                            height={18}
                                                            icon="solar:text-square-outline"
                                                            width={18}
                                                        />
                                                    }
                                                    onChange={(e) => handlePairChange(index, 'value', e.target.value)}
                                                    className="w-1/2"
                                                />
                                                <Input
                                                    variant="bordered"
                                                    type="text"
                                                    value={pair.description}
                                                    label={`Beschreibung ${index + 1}`}
                                                    startContent={
                                                        <Icon
                                                            className="text-default-400 pointer-events-none"
                                                            height={18}
                                                            icon="solar:document-text-outline"
                                                            width={18}
                                                        />
                                                    }
                                                    onChange={(e) => handlePairChange(index, 'description', e.target.value)}
                                                    className="w-1/2"
                                                />
                                                {valueDescPairs.length >= 3 && (
                                                    <Tooltip showArrow={true} content="Paar löschen">
                                                        <Button isIconOnly color="primary" aria-label="Delete" size='lg' radius='full' onClick={() => removePair(index)}>
                                                            <Icon
                                                                className="text-white pointer-events-none"
                                                                height={18}
                                                                icon="solar:trash-bin-minimalistic-outline"
                                                                width={18}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        ))}
                                        <Button color='primary' onClick={addPair}>
                                            Weitere Option hinzufügen
                                        </Button>
                                    </>
                                )}

                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" isLoading={isCreatingFilter} onPress={() => {
                                    if (validateForm()) {
                                        createFilter();
                                        onClose();
                                    }
                                }}>
                                    Filter hinzufügen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default FilterList;

const columns = [
    { name: "ID", uid: "id", sortable: true },
    { name: "Filter", uid: "name", sortable: true },
    { name: "Typ", uid: "type", sortable: true },
    { name: "Optionen", uid: "actions" },
];

export { columns };
