import CookieHelper from "../helpers/getCookieHelper";
import AdminUser from "../models/adminUser";

interface ApiResponse<T> {
    response: T | null;
    error: string | null;
}

const adminController = {
    getAllAdminUsers: function (): Promise<ApiResponse<AdminUser[]>> {
        return new Promise((resolve) => {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
            const token = CookieHelper.get('happyActiveUser');

            if (!apiUrl || !bearerToken) {
                resolve({ response: null, error: "Missing API configuration." });
                return;
            }

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
                'bearer': bearerToken,
                ...(token ? { 'usertoken': token } : {})
            };

            fetch(`${apiUrl}adminuser`, {
                method: 'GET',
                headers,
            })
                .then(response => response.json())
                .then(json => {
                    if (json.response && Array.isArray(json.response)) {
                        const adminUsers = json.response.map((item: any) => new AdminUser(item));
                        resolve({ response: adminUsers, error: null });
                    } else {
                        resolve({ response: null, error: json.error || "Unexpected response format" });
                    }
                })
                .catch(error => {
                    console.error(error);
                    resolve({ response: null, error: error.toString() });
                });
        });
    },

    createAdmin: function (userData: Partial<AdminUser>): Promise<ApiResponse<any>> {
        return new Promise((resolve) => {
            const apiUrl = process.env.REACT_APP_API_BASE_URL;
            const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
            const token = CookieHelper.get('happyActiveUser');

            if (!apiUrl || !bearerToken) {
                resolve({ response: null, error: "Missing API configuration." });
                return;
            }

            const headers: HeadersInit = {
                'Content-Type': 'application/json',
                'bearer': bearerToken,
                ...(token ? { 'usertoken': token } : {})
            };

            fetch(`${apiUrl}moderator/create`, {
                method: 'POST',
                headers,
                body: JSON.stringify(userData),
            })
                .then(response => response.json())
                .then(json => {
                    if (json.response) {
                        resolve({ response: json.response, error: null });
                    } else {
                        resolve({ response: null, error: json.error || "Failed to create admin user" });
                    }
                })
                .catch(error => {
                    console.error(error);
                    resolve({ response: null, error: error.toString() });
                });
        });
    },
};

export default adminController;