import React from 'react';
import { useParams, useLocation } from 'react-router-dom';

const CategoryDetail = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  return (
    <div>
      <h2>Category Detail</h2>
      {id ? (
        <p>Displaying details for category ID: {id}</p>
      ) : (
        <p>No category ID provided.</p>
      )}
    </div>
  );
};

export default CategoryDetail;
