"use client";

import React, { useState, useEffect } from "react";
import { Table, TableHeader, TableColumn, TableBody, TableRow, TableCell, Spinner, Spacer } from '@nextui-org/react';
import { cn } from "@nextui-org/react";
import configController from "@/api/configController";

const GenderConfig = React.forwardRef(({ className, ...props }, ref) => {
  const [genders, setGenders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { response, error } = await configController.getAllGenders();
      if (response) {
        setGenders(response);
      } else {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Geschlechter</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          Welche Geschlechter stehen zur Verfügung und wie werden sie angezeigt?
        </p>
      </div>
      <Spacer y={4} />
      <Table aria-label="Gender Configuration Table" removeWrapper loadingState={isLoading ? "loading" : "idle"}>
        <TableHeader>
          <TableColumn key="id">ID</TableColumn>
          <TableColumn key="name">Name</TableColumn>
          <TableColumn key="emoji">Emoji</TableColumn>
          <TableColumn key="description">Beschreibung</TableColumn>
          <TableColumn key="createdAt">Erstellt am</TableColumn>
          <TableColumn key="updatedAt">Aktualisiert am</TableColumn>
        </TableHeader>
        <TableBody
          emptyContent={"Keine Geschlechter verfügbar"}
          loadingContent={<Spinner />}
        >
          {genders.map(gender => (
            <TableRow key={gender.id}>
              <TableCell>{gender.id}</TableCell>
              <TableCell>{gender.name}</TableCell>
              <TableCell>{gender.emoji}</TableCell>
              <TableCell>{gender.description}</TableCell>
              <TableCell>{new Date(gender.createdAt).toLocaleString()}</TableCell>
              <TableCell>{new Date(gender.updatedAt).toLocaleString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
});

GenderConfig.displayName = "GenderConfig";

export default GenderConfig;