import {
    Button, DatePicker, Input, Spacer, Switch, Textarea, Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Pagination,
    Spinner,
    Tooltip,
    User,
} from '@nextui-org/react';
import React, { useMemo, useState, useEffect } from 'react';
import { now, getLocalTimeZone, parseAbsolute } from "@internationalized/date";
import maintenanceController from '../api/maintenanceController';
import useToast from '../hooks/useToast';
import { I18nProvider } from '@react-aria/i18n';
import { Icon } from '@iconify/react/dist/iconify.js';

const AppMaintenance = () => {
    const { showToast } = useToast();

    const [isMaintenanceMode, setIsMaintenanceMode] = useState(false);
    const [canEditBaseUrl, setCanEditBaseUrl] = useState(false);
    const [startDate, setStartDate] = useState(now(getLocalTimeZone()));
    const [endDate, setEndDate] = useState(now(getLocalTimeZone()));
    const [baseUrl, setBaseUrl] = useState('');
    const [iosVersion, setIosVersion] = useState('');
    const [androidVersion, setAndroidVersion] = useState('');
    const [maintenanceMessage, setMaintenanceMessage] = useState('');
    const [maintenanceHistory, setMaintenanceHistory] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const [baseUrlError, setBaseUrlError] = useState(false);
    const [iosError, setIosError] = useState(false);
    const [androidError, setAndroidError] = useState(false);
    const [startError, setStartError] = useState(false);
    const [endError, setEndError] = useState(false);
    const [textError, setTextError] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const { response, error } = await maintenanceController.getMaintenanceHistory();
            if (response) {
                setMaintenanceHistory(response);
                if (response.length > 0) {
                    const firstEntry = response[0];
                    setBaseUrl(firstEntry.baseurl || '');
                    setIosVersion(firstEntry.ios || '');
                    setAndroidVersion(firstEntry.android || '');
                    if (firstEntry.maintenance_start) {
                        setStartDate(parseAbsolute(firstEntry.maintenance_start, getLocalTimeZone()));
                    }
                    if (firstEntry.maintenance_end) {
                        setEndDate(parseAbsolute(firstEntry.maintenance_end, getLocalTimeZone()));
                    }
                    if (firstEntry.maintenance_start || firstEntry.maintenance_end) {
                        setIsMaintenanceMode(true);
                    }
                    setMaintenanceMessage(firstEntry.maintenance_message || '');
                    if (firstEntry.maintenance_start) {
                        handleSwitchChange();
                    }
                }
            } else {
                console.error(error);
            }
            setIsLoading(false);
        };
        fetchData();
    }, []);

    const handleSwitchChange = () => {
        setIsMaintenanceMode(!isMaintenanceMode);
    };

    const changeCanEditBaseUrl = () => {
        setCanEditBaseUrl(!canEditBaseUrl);
    };

    const handleSaveConfig = async () => {
        if (!baseUrl) {
            setBaseUrlError(true);
            showToast("Base-URL darf nicht leer sein.", 'error');
            return;
        }

        const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
        if (!urlRegex.test(baseUrl)) {
            setBaseUrlError(true);
            showToast("Ungültiges URL-Format.", 'error');
            return;
        }

        setBaseUrlError(false);

        if (!iosVersion || !androidVersion) {
            if (!iosVersion) {
                setIosError(true);
            }
            if (!androidVersion) {
                setAndroidError(true);
            }
            showToast("iOS und Android Mindestversion dürfen nicht leer sein.", 'error');
            return;
        }

        const versionRegex = /^[0-9]+(\.[0-9]+)*$/;

        if (!versionRegex.test(iosVersion) || !versionRegex.test(androidVersion)) {
            if (!versionRegex.test(iosVersion)) setIosError(true);
            if (!versionRegex.test(androidVersion)) setAndroidError(true);
            showToast('Ungültiges Versionsformat. Nur Zahlen und "." sind erlaubt. Zahlen müssen am Anfang und Ende stehen.', 'error');
            return;
        }

        if (isMaintenanceMode) {
            const now = new Date();
            if (new Date(startDate) <= now || new Date(endDate) <= now) {
                showToast("Start und Enddatum müssen in der Zukunft liegen.", 'error');
                return;
            }

            if (new Date(endDate) <= new Date(startDate)) {
                showToast("Enddatum muss nach dem Startdatum liegen.", 'error');
                return;
            }

            if (!maintenanceMessage) {
                showToast("Wartungstext darf nicht leer sein.", 'error');
                return;
            }
        }

        const config = {
            baseurl: baseUrl,
            ios: iosVersion,
            android: androidVersion,
            maintenance_start: isMaintenanceMode ? startDate.toString() : null,
            maintenance_end: isMaintenanceMode ? endDate.toString() : null,
            maintenance_message: isMaintenanceMode ? maintenanceMessage : null,
        };

        const { response, error } = await maintenanceController.saveMaintenanceConfig(config);
        if (response) {
            showToast(`Konfiguration erfolgreich gespeichert.`, 'success');
            setMaintenanceHistory(response);
        } else {
            console.error(error);
            showToast(`Fehler beim Speichern der Konfiguration. ${error}`, 'error');
        }
    };

    const rowsPerPage = 10;
    const pages = useMemo(() => {
        return maintenanceHistory.length ? Math.ceil(maintenanceHistory.length / rowsPerPage) : 0;
    }, [maintenanceHistory.length, rowsPerPage]);

    const loadingState = isLoading ? "loading" : "idle";

    const handleVersionChange = (setVersion, setError) => (e) => {
        const value = e.target.value;
        const versionRegex = /^[0-9.]*$/;
        if (versionRegex.test(value) && /^[0-9]+(\.[0-9]+)*$/.test(value)) {
            setVersion(value);
            setError(false);
        } else if (value === '') {
            setVersion(value);
            setError(false);
        } else {
            setError(true);
            showToast('Ungültiges Versionsformat. Nur Zahlen und "." sind erlaubt. Zahlen müssen am Anfang und Ende stehen.', 'error');
        }
    };

    const getKeyValue = (item, key) => {
        if (key === 'created_at') {
            return `${item.created_at ? new Date(item.created_at).toLocaleString() : "-"}`
        }
        if (key === 'maintenance_start') {
            return `${item.maintenance_start ? new Date(item.maintenance_start).toLocaleString() : "-"}`
        }
        if (key === 'maintenance_end') {
            return `${item.maintenance_end ? new Date(item.maintenance_end).toLocaleString() : "-"}`
        }
        if (key === 'created_by') {
            return <User
                avatarProps={{ src: item.created_by.picture }}
                description={<a href={`mailto:${item.created_by.email}`}>{item.created_by.email}</a>}
                name={`${item.created_by.firstname} ${item.created_by.lastname}`}
            >
                <a href={`mailto:${item.created_by.email}`}>{item.created_by.email}</a>
            </User>;
        }
        if (item.hasOwnProperty(key)) {
            return item[key];
        }
        return null;
    };

    return (
        <div className="w-full flex-1">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">App-Wartung</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Base-URL und Mindestversionen festlegen sowie Wartungsmodus (de-) aktivieren.
            </h2>
            <p>Alle Angaben werden an die Firebase Remote-Config übermittelt, die asynchron zum Appstart abgefragt wird.</p>
            <p>Der Wartungs bzw. Updatemodus kann somit auch getriggert werden, wenn die API ausfällt oder inkompatbiel zur veralteten Appversion ist.</p>
            <Spacer y={4} />
            <p>Die Änderung der Base-URL sollte nur temporär und in Notfällen hier vorgenommen werden. Die Änderung tritt ebenfalls aynchron in Kraft und ggf. nur, wenn die Urpsrungs-URL nicht verfügbar ist.</p>
            <Spacer y={4} />
            <h1 className='text-lg font-bold'>API Base-URL</h1>
            <Spacer y={2} />
            <Input
                variant='bordered'
                type="text"
                label="API Base-URL"
                value={baseUrl}
                disabled={!canEditBaseUrl}
                isReadOnly={!canEditBaseUrl}
                isInvalid={baseUrlError}
                labelPlacement="inside"
                startContent={
                    <Icon
                        className="text-default-400 pointer-events-none"
                        height={18}
                        icon="solar:link-round-angle-outline"
                        width={18}
                    />
                }
                onChange={(e) => {
                    setBaseUrl(e.target.value);
                    setBaseUrlError(false);
                }}
                endContent={
                    <Tooltip showArrow={true} content="Bearbeiten">
                        <button className="focus:outline-none" type="button" onClick={changeCanEditBaseUrl}>
                            {canEditBaseUrl ? (
                                <Icon
                                    className="text-default-400 pointer-events-none"
                                    height={18}
                                    icon="solar:lock-keyhole-linear"
                                    width={18}
                                />
                            ) : (
                                <Icon
                                    className="text-default-400 pointer-events-none"
                                    height={18}
                                    icon="solar:lock-keyhole-minimalistic-unlocked-linear"
                                    width={18}
                                />
                            )}
                        </button>
                    </Tooltip>
                }
            />

            <Spacer y={4} />
            <h1 className='text-lg font-bold'>Mindestversionen</h1>
            <Spacer y={2} />
            <div className="flex justify-between">
                <Input
                    variant='bordered'
                    type="text"
                    label="iOS Mindestversion"
                    value={iosVersion}
                    isInvalid={iosError}
                    labelPlacement="inside"
                    startContent={
                        <Icon
                            className="text-default-400 pointer-events-none"
                            height={18}
                            icon="solar:iphone-outline"
                            width={18}
                        />
                    }
                    onChange={handleVersionChange(setIosVersion, setIosError)}
                />
                <Spacer x={2} />
                <Input
                    variant='bordered'
                    type="text"
                    label="Android Mindestversion"
                    value={androidVersion}
                    isInvalid={androidError}
                    labelPlacement="inside"
                    startContent={
                        <Icon
                            className="text-default-400 pointer-events-none"
                            height={18}
                            icon="solar:iphone-outline"
                            width={18}
                        />
                    }
                    onChange={handleVersionChange(setAndroidVersion, setAndroidError)}
                />
            </div>
            <Spacer y={4} />
            <h1 className='text-lg font-bold'>Wartungsmodus</h1>
            <Spacer x={2} />
            <Switch checked={isMaintenanceMode} onChange={handleSwitchChange}>
                Wartungsmodus aktivieren / planen
            </Switch>
            {isMaintenanceMode && (
                <>
                    <Spacer y={2} />
                    <div className="flex justify-between">
                        <I18nProvider locale="de-DE">
                            <DatePicker
                                variant='bordered'
                                defaultValue={startDate}
                                hideTimeZone
                                showMonthAndYearPickers
                                hourCycle={24}
                                label="Startzeit"
                                isInvalid={startError}
                                minValue={now(getLocalTimeZone())}
                                onChange={(e) => {
                                    setStartDate(e);
                                    setStartError(false);
                                }}
                            /></I18nProvider>
                        <Spacer x={2} />
                        <I18nProvider locale="de-DE">
                            <DatePicker
                                variant='bordered'
                                defaultValue={endDate}
                                hideTimeZone
                                showMonthAndYearPickers
                                hourCycle={24}
                                label="Endzeit"
                                isInvalid={endError}
                                minValue={startDate}
                                onChange={(e) => {
                                    setEndDate(e);
                                    setEndError(false);
                                }}
                            />
                        </I18nProvider>
                    </div>
                    <Spacer y={2} />
                    <Textarea
                        variant='bordered'
                        label="Wartungstext"
                        value={maintenanceMessage}
                        placeholder="Aufgrund einer Serverwartung sind wir für einige Zeit nicht erreichbar."
                        labelPlacement="inside"
                        isInvalid={textError}
                        onChange={(e) => {
                            setMaintenanceMessage(e.target.value);
                            setTextError(false);
                        }}
                    />
                </>
            )}
            <Spacer y={4} />
            <Button color="primary" onClick={handleSaveConfig}>
                Appversionen & Wartungseinstellungen speichern
            </Button>
            <Spacer y={4} />
            <h1 className='text-lg font-bold'>Historie</h1>
            <Spacer x={2} />
            <Table
                aria-label="Maintenance History Table"
                removeWrapper
                bottomContent={
                    pages > 0 ? (
                        <div className="flex w-full justify-center">
                            <Pagination
                                isCompact
                                showControls
                                showShadow
                                color="primary"
                                page={page}
                                total={pages}
                                onChange={(page) => setPage(page)}
                            />
                        </div>
                    ) : null
                }
            >
                <TableHeader>
                    <TableColumn key="created_at">Erstellt</TableColumn>
                    <TableColumn key="baseurl">Base-URL</TableColumn>
                    <TableColumn key="ios">iOS</TableColumn>
                    <TableColumn key="android">Android</TableColumn>
                    <TableColumn key="maintenance_start">W-Mod. Start</TableColumn>
                    <TableColumn key="maintenance_end">W-Mod. Ende</TableColumn>
                    <TableColumn key="maintenance_message">W-Mod. Text</TableColumn>
                    <TableColumn key="created_by">Admin</TableColumn>
                </TableHeader>
                <TableBody
                    emptyContent={"Noch keine History-Einträge"}
                    items={maintenanceHistory}
                    loadingContent={<Spinner />}
                    loadingState={loadingState}
                >
                    {(item) => (
                        <TableRow key={item?.id}>
                            {(columnKey) => <TableCell>{getKeyValue(item, columnKey)}</TableCell>}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    );
};

export default AppMaintenance;
