import CookieHelper from "../helpers/getCookieHelper";
import Category from "../models/category";

interface ApiResponse<T> {
  response: T | null;
  error: string | null;
}

const categoryController = {
  getAllCategories: function (): Promise<ApiResponse<Category[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}categories`, {
        method: 'GET',
        headers,
      })
        .then(response => response.json())
        .then(json => {
          if (json.response && Array.isArray(json.response)) {
            const categories = json.response.map((item: any) => new Category(item));
            resolve({ response: categories, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected response format" });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  getCategoryData: function (id: number): Promise<ApiResponse<Category[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}category/${id}/data`, {
        method: 'GET',
        headers,
      })
        .then(response => response.json())
        .then(json => {
          if (json.response) {
            resolve({ response: json.response, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected response format" });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  createCategory: function (categoryData: Partial<Category>): Promise<ApiResponse<Category>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}categories`, {
        method: 'POST',
        headers,
        body: JSON.stringify(categoryData),
      })
        .then(response => response.json())
        .then(json => {
          if (json.response) {
            resolve({ response: json.response, error: null });
          } else {
            resolve({ response: null, error: json.error || "Category creation failed" });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  editCategory: function (categoryData: Partial<Category>, id: number): Promise<ApiResponse<Category>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}category/${id}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify(categoryData),
      })
        .then(response => response.json())
        .then(json => {
          if (json.response) {
            resolve({ response: json.response, error: null });
          } else {
            resolve({ response: null, error: json.error || "Category creation failed" });
          }
        })
        .catch(error => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  deactivateCategory: async (categoryId: string): Promise<ApiResponse<boolean>> => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
    const token = CookieHelper.get('happyActiveUser');

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      ...(bearerToken ? { 'bearer': bearerToken } : {}),
      'usertoken': token || '',
    };

    try {
      const result = await fetch(`${apiUrl}/category/${categoryId}/toggle`, {
        method: 'PUT',
        headers,
      });

      const json = await result.json();

      if (json.response) {
        return { response: true, error: null };
      } else {
        throw new Error(json.error || "Failed to deactivate category");
      }
    } catch (error) {
      console.error(error);
      return { response: null, error: (error as Error).message };
    }
  },

  deleteCategory: async (categoryId: string): Promise<ApiResponse<boolean>> => {
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
    const token = CookieHelper.get('happyActiveUser');

    const headers: HeadersInit = {
      'Content-Type': 'application/json',
      ...(bearerToken ? { 'bearer': bearerToken } : {}),
      'usertoken': token || '',
    };

    try {
      const result = await fetch(`${apiUrl}/category/${categoryId}`, {
        method: 'DELETE',
        headers,
      });

      const json = await result.json();

      if (json.response) {
        return { response: true, error: null };
      } else {
        throw new Error(json.error || "Failed to delete category");
      }
    } catch (error) {
      console.error(error);
      return { response: null, error: (error as Error).message };
    }
  },
};

export default categoryController;