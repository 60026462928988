"use client";

import React from "react";
import { Spacer } from "@nextui-org/react";
import { cn } from "@nextui-org/react";

const ComingSoon = React.forwardRef(({ className, ...props }, ref) => {

  return (
    <div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Coming Soon</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          Hier kommen demnächst andere Inhalte.
        </p>
      </div>
    </div>
  );
});

ComingSoon.displayName = "ComingSoon";

export default ComingSoon;
