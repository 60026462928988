import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination,
    useDisclosure,
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DatePicker,
    Spinner,
    Link,
    Spacer,
} from "@nextui-org/react";
import adminController from '../api/adminController';
import AgeHelper from '../helpers/getAgeHelper';
import PasswordHelper from '../helpers/getPasswordHelper';
import { parseDate } from "@internationalized/date";
import { I18nProvider } from '@react-aria/i18n';
import { Icon } from "@iconify/react";

const statusColorMap = {
    aktiv: "success",
    deaktiviert: "danger",
};
const INITIAL_VISIBLE_COLUMNS = ["name", "position", "lastlogin", "status", "actions"];

const adaptUserData = (user) => ({
    id: user.id,
    picture: user.picture,
    name: `${user.firstname} ${user.lastname}`,
    age: user.birthdate,
    position: user.role,
    email: user.email,
    lastlogin: user.last_login,
    created: user.created_at,
    status: user.deactivated_at ? 'deaktiviert' : 'aktiv',
});

const Admin = () => {
    // CREATE USER STUFF
    const { isOpen: isCreateUserModalOpen, onOpen: onCreateUserModalOpen, onClose: onCreateUserModalClose } = useDisclosure();
    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);
    const [isCreatingUser, setIsCreatingUser] = useState(false);

    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [birthdate, setBirthdate] = useState(parseDate(new Date().toISOString().split('T')[0]));
    const [position, setPosition] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleGeneratePassword = () => {
        const newPassword = PasswordHelper.get();
        setPassword(newPassword);
    };

    const validateForm = () => {
        let valid = true;

        if (firstname.length < 2) {
            setFirstnameError(true);
            valid = false;
        }

        if (lastname.length < 2) {
            setLastnameError(true);
            valid = false;
        }

        if (!birthdate || new Date(birthdate) >= new Date()) {
            setBirthdateError(true);
            valid = false;
        }

        if (position.length < 3) {
            setPositionError(true);
            valid = false;
        }

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(email)) {
            setEmailError(true);
            valid = false;
        } else {
            setEmailError(false);
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&()_+])[A-Za-z\d@$!%*?&()_+]{8,}$/;
        if (!passwordRegex.test(password)) {
            setPasswordError(true);
            valid = false;
        } else {
            setPasswordError(false);
        }

        return valid;
    };

    const createUser = async () => {
        setIsCreatingUser(true);
        if (validateForm()) {
            const userData = {
                email,
                password,
                role: position,
                firstname,
                lastname,
                birthdate: new Date(birthdate).toLocaleDateString('de-DE'),
            };

            try {
                const { response, error } = await adminController.createAdmin(userData);
                if (error) {
                    console.error("Error creating admin:", error);
                } else {
                    console.log("Admin created successfully:", response);
                    onCreateUserModalClose();
                    fetchUsers();
                }
            } catch (error) {
                console.error("Error creating admin:", error);
            } finally {
                setIsCreatingUser(false);
            }
        } else {
            setIsCreatingUser(false);
        }
    };

    // ERRORS
    const [firstnameError, setFirstnameError] = useState(false);
    const [lastnameError, setLastnameError] = useState(false);
    const [birthdateError, setBirthdateError] = useState(false);
    const [positionError, setPositionError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);


    // TABLE RELEVANT

    // LOAD USERS
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const { response, error } = await adminController.getAllAdminUsers();
            if (error) {
                console.error(error);
            } else {
                setUsers(response.map(adaptUserData));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // TABLE SORTING
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "id",
        direction: "descending",
    });

    const [page, setPage] = useState(1);
    const hasSearchFilter = Boolean(filterValue);
    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return columns;
        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);
    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...users];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.name.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(statusFilter).includes(user.status),
            );
        }

        return filteredUsers;
    }, [users, filterValue, statusFilter, hasSearchFilter,]);
    const pages = Math.ceil(filteredItems.length / rowsPerPage);
    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);
    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);
    const renderCell = useCallback((user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "name":
                return (
                    <User
                        avatarProps={{ src: user.picture }}
                        description={<a href={`mailto:${user.email}`}>{user.email}</a>}
                        name={user.name}
                    >
                        <a href={`mailto:${user.email}`}>{user.email}</a>
                    </User>
                );
            case "age":
                return `${new Date(user.age).toLocaleDateString()} (${AgeHelper.get(user.age)})`;
            case "position":
                return (
                    <div className="flex flex-col">
                        <p className="text-bold text-small">{user.position}</p>
                    </div>
                );
            case "lastlogin":
                return (
                    <div className="flex flex-col">
                        <p className="text-bold text-small">
                            {user.lastlogin ? new Date(user.lastlogin).toLocaleString() : "-"}
                        </p>
                    </div>
                );
            case "created":
                return new Date(user.created).toLocaleString();
            case "status":
                return (
                    <Chip color={statusColorMap[user.status]} size="sm" variant="flat">
                        {user.status}
                    </Chip>
                );
            case "email":
                return (
                    <div className="flex flex-col">
                        <p className="text-bold text-small">{<a href={`mailto:${user.email}`}>{user.email}</a>}</p>
                    </div>
                );
            case "actions":
                return (
                    <div className="relative flex justify-end items-center gap-2">
                        <Dropdown className="w-[250px]">
                            <DropdownTrigger>
                                <Button isIconOnly size="sm" variant="light">
                                    <Icon
                                        className='text-default-300'
                                        height={18}
                                        icon="solar:menu-dots-bold"
                                        width={18}
                                    />
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem
                                    description="Profildaten & Rechte"
                                    startContent={<Icon
                                        height={18}
                                        icon="solar:pen-2-outline"
                                        width={18}
                                    />}
                                >
                                    Bearbeiten
                                </DropdownItem>
                                <DropdownItem
                                    description="Login sperren"
                                    startContent={<Icon
                                        height={18}
                                        icon="solar:user-block-outline"
                                        width={18}
                                    />}
                                >
                                    Deaktivieren
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);
    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        variant='bordered'
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Suche nach Namen oder Modulen..."
                        startContent={<Icon
                            className="text-default-500"
                            height={18}
                            icon="solar:magnifer-outline"
                            width={18}
                        />}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<Icon
                                    className="text-small"
                                    height={18}
                                    icon="solar:alt-arrow-down-outline"
                                    width={18}
                                />} variant="flat">
                                    Status
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={statusFilter}
                                selectionMode="multiple"
                                onSelectionChange={setStatusFilter}
                            >
                                {statusOptions.map((status) => (
                                    <DropdownItem key={status.uid}>
                                        {status.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<Icon
                                    className="text-small"
                                    height={18}
                                    icon="solar:alt-arrow-down-outline"
                                    width={18}
                                />} variant="flat">
                                    Felder
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid}>
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Button color="primary" onClick={onCreateUserModalOpen}>
                            Nutzer hinzufügen
                        </Button>
                    </div>
                </div>
                <div className="flex justify-between items-center pt-4">
                    <span className="text-default-400 text-small">Insgesamt {users.length} Nutzer</span>
                    <label className="flex items-center text-default-400 text-small">
                        Zeilen pro Seite:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        users.length,
        onSearchChange,
        onClear,
        onCreateUserModalOpen,
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
            </div>
        );
    }, [page, pages]);

    return (
        <>
            <div className="w-full flex-1">
                <div className="flex items-center gap-x-3">
                    <h1 className="text-3xl font-bold leading-9 text-default-foreground">Adminverwaltung</h1>
                </div>
                <h2 className="mt-2 text-small text-default-500 mb-6">
                    Verwalte alle Admin Nutzer des Dashboards und deren Rechte.
                </h2>
                {loading ? (
                    <Spinner />
                ) : (
                    <Table
                        aria-label="Admin Users Table"
                        isHeaderSticky
                        bottomContent={bottomContent}
                        bottomContentPlacement="outside"
                        selectedKeys={selectedKeys}
                        selectionMode="none"
                        sortDescriptor={sortDescriptor}
                        topContent={topContent}
                        removeWrapper
                        topContentPlacement="outside"
                        onSelectionChange={setSelectedKeys}
                        onSortChange={setSortDescriptor}
                    >
                        <TableHeader columns={headerColumns}>
                            {(column) => (
                                <TableColumn
                                    key={column.uid}
                                    align={column.uid === "actions" ? "center" : "start"}
                                    allowsSorting={column.sortable}
                                >
                                    {column.name}
                                </TableColumn>
                            )}
                        </TableHeader>
                        <TableBody emptyContent={"Keine Nutzer gefunden"} items={sortedItems}>
                            {(item) => (
                                <TableRow key={item.id}>
                                    {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                )}
            </div>
            <Modal backdrop="blur" isOpen={isCreateUserModalOpen} onClose={onCreateUserModalClose} placement="bottom-center" size='3xl' isDismissable={false}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Nutzer hinzufügen</ModalHeader>
                            <ModalBody>
                                <div className="flex justify-between">
                                    <Input
                                        variant='bordered'
                                        type="text"
                                        label="Vorname"
                                        value={firstname}
                                        defaultValue=""
                                        labelPlacement="inside"
                                        isInvalid={firstnameError}
                                        onChange={(e) => {
                                            setFirstname(e.target.value);
                                            setFirstnameError(false);
                                        }}
                                    />
                                    <Spacer x={2} />
                                    <Input
                                        variant='bordered'
                                        type="text"
                                        label="Nachname"
                                        value={lastname}
                                        defaultValue=""
                                        labelPlacement="inside"
                                        isInvalid={lastnameError}
                                        onChange={(e) => {
                                            setLastname(e.target.value);
                                            setLastnameError(false);
                                        }}
                                    />
                                </div>
                                <div className="flex justify-between">
                                    <I18nProvider locale="de-DE">
                                        <DatePicker
                                            variant='bordered'
                                            label="Geburtsdatum"
                                            startContent={
                                                <Icon
                                                    className="text-default-400 pointer-events-none"
                                                    height={18}
                                                    icon="solar:confetti-outline"
                                                    width={18}
                                                />
                                            }
                                            showMonthAndYearPickers
                                            value={birthdate}
                                            defaultValue={""}
                                            labelPlacement='inside'
                                            isInvalid={birthdateError}
                                            onChange={(e) => {
                                                setBirthdate(e);
                                                setBirthdateError(false);
                                            }}
                                        />
                                    </I18nProvider>
                                    <Spacer x={2} />
                                    <Input
                                        variant='bordered'
                                        type="text"
                                        label="Position"
                                        value={position}
                                        defaultValue=""
                                        labelPlacement="inside"
                                        isInvalid={positionError}
                                        startContent={
                                            <Icon
                                                className="text-default-400 pointer-events-none"
                                                height={18}
                                                icon="solar:medal-star-circle-outline"
                                                width={18}
                                            />
                                        }
                                        onChange={(e) => {
                                            setPosition(e.target.value);
                                            setPositionError(false);
                                        }}
                                    />
                                </div>
                                <Spacer y={2} />
                                <Input
                                    variant='bordered'
                                    type="text"
                                    label="E-Mail / Benutzername"
                                    value={email}
                                    defaultValue=""
                                    labelPlacement="inside"
                                    isInvalid={emailError}
                                    startContent={
                                        <Icon
                                            className="text-default-400 pointer-events-none"
                                            height={18}
                                            icon="solar:user-linear"
                                            width={18}
                                        />
                                    }
                                    onChange={(e) => {
                                        setEmail(e.target.value.replace(/\s/g, ''));
                                        setEmailError(false);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === ' ' || e.key === 'Spacebar') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                                <div className="flex justify-between">
                                    <Input
                                        variant='bordered'
                                        type={isVisible ? "text" : "password"}
                                        label="Passwort"
                                        defaultValue=""
                                        labelPlacement="inside"
                                        value={password}
                                        isInvalid={passwordError}
                                        startContent={
                                            <Icon
                                                className="text-default-400 pointer-events-none"
                                                height={18}
                                                icon="solar:lock-keyhole-linear"
                                                width={18}
                                            />
                                        }
                                        endContent={
                                            <button className="focus:outline-none" type="button" onClick={toggleVisibility}>
                                                {isVisible ? (
                                                    <Icon
                                                        className="text-default-400 pointer-events-none"
                                                        height={18}
                                                        icon="solar:eye-closed-outline"
                                                        width={18}
                                                    />
                                                ) : (
                                                    <Icon
                                                        className="text-default-400 pointer-events-none"
                                                        height={18}
                                                        icon="solar:eye-outline"
                                                        width={18}
                                                    />
                                                )}
                                            </button>
                                        }
                                        onChange={(e) => {
                                            setPassword(e.target.value.replace(/\s/g, ''));
                                            setPasswordError(false);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === ' ' || e.key === 'Spacebar') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <Spacer x={2} />
                                    <Link onPress={handleGeneratePassword}>Generieren</Link>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" isLoading={isCreatingUser} onPress={() => {
                                    if (validateForm()) {
                                        createUser();
                                        onClose();
                                    }
                                }}>
                                    Nutzer hinzufügen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default Admin;

const columns = [
    { name: "ID", uid: "id", },
    { name: "Name", uid: "name", sortable: true },
    { name: "Alter", uid: "age", sortable: true },
    { name: "Position", uid: "position", },
    { name: "E-Mail", uid: "email" },
    { name: "Last Login", uid: "lastlogin", sortable: true },
    { name: "Erstellt", uid: "created", sortable: true },
    { name: "Status", uid: "status", sortable: true },
    { name: "Optionen", uid: "actions" },
];

const statusOptions = [
    { name: "Aktiv", uid: "aktiv" },
    { name: "Deaktiviert", uid: "deaktiviert" },
];

export { columns, statusOptions };
