import { toast, ToastOptions, ToastContent } from 'react-toastify';

type ToastType = 'success' | 'error' | 'warning' | 'info';

const useToast = () => {
  const showToast = (message: ToastContent, type: ToastType = 'info', options?: ToastOptions) => {
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast.info(message, options);
        break;
    }
  };

  return { showToast };
};

export default useToast;