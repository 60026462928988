import categoriesController from '@/api/categoryController';
import filterController from '@/api/filterController';
import useToast from '@/hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Autocomplete, AutocompleteItem, Button, Checkbox, Divider, Input, Spacer, Spinner, Textarea, Tooltip } from '@nextui-org/react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import JsonView from 'react18-json-view'
import 'react18-json-view/src/style.css'

const CategoryEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const isEditing = location.pathname === '/categories/edit';

  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);

  const categoriesRef = useRef([]);
  const filtersRef = useRef([]);

  const [isCreatingCategory, setIsCreatingCategory] = useState(false);
  const [catVal, setCatVal] = useState(null);
  const [filterVal, setFilterVal] = useState(null);

  const [catName, setCatName] = useState('');
  const [catIconUrl, setCatIconUrl] = useState('');
  const [catDesc, setCatDesc] = useState('');
  const [catKeywords, setCatKeywords] = useState([]);
  const [catSlug, setCatSlug] = useState('');

  const [catNameError, setCatNameError] = useState(false);
  const [catIconUrlError, setCatIconUrlError] = useState(false);
  const [catDescError, setCatDescError] = useState(false);
  const [catKeywordsError, setCatKeywordsError] = useState(false);
  const [catSlugError, setCatSlugError] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  useEffect(() => {
    const initialize = async () => {
      setLoading(true);
      try {
        await fetchCategories();
        await fetchFilters();

        if (isEditing && id) {
          await fetchCategoryDetails(id);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    initialize();
  }, [isEditing, id]);

  const fetchCategories = async () => {
    try {
      const { response, error } = await categoriesController.getAllCategories();
      if (!error)
        categoriesRef.current = response;
    } catch (error) {
    }
  };

  const fetchFilters = async () => {
    try {
      const { response, error } = await filterController.getAllFilters();
      if (!error)
        filtersRef.current = response;
    } catch (error) {
    }
  };

  const fetchCategoryDetails = async () => {
    setLoading(true);
    try {
      const { response, error } = await categoriesController.getCategoryData(id);
      if (error) {
        showToast("Fehler beim Laden der Kategorie-Daten.", 'error');
        return;
      }

      const { category, filters } = response;

      setCatName(category.title ?? '');
      setCatIconUrl(category.iconUrl ?? '');
      setCatDesc(category.description ?? '');
      setCatKeywords(category.keywords ?? []);
      setCatSlug(category.slug ?? '');
      setCatVal(category.parentId ? category.parentId.toString() : null);

      const formattedFilters = (filters ?? []).map((filter) => {
        const matchedFilter = filtersRef.current.find((f) => f.id === parseInt(filter.id, 10));

        return {
          ...matchedFilter,
          id: matchedFilter ? parseInt(matchedFilter.id, 10) : null,
          mandatory: filter.isMandatory ?? false,
          order: filter.order ?? 1,
          excludes: (filter.exceptions ?? []).map((exception) => ({
            filterId: exception.id ? exception.id.toString() : null,
            option: exception.optId ? exception.optId.toString() : null,
            action: exception.actionType,
            defaultOption: exception.defOptId ? exception.defOptId.toStzring() : null,
          })),
        };
      });

      formattedFilters.sort((a, b) => a.order - b.order);
      setSelectedFilters(formattedFilters);
    } catch (error) {
      showToast("Ein Fehler ist aufgetreten beim Abrufen der Kategorie-Details.", 'error');
    } finally {
      setLoading(false);
    }
  };

  const flattenCategories = (categories) => {
    const flatList = [];
    const traverse = (cat, depth = 0) => {
      flatList.push({ value: cat.id, label: `${'.'.repeat(depth * 3)}${cat.name}` });
      cat.subcats?.forEach((subcat) => traverse(subcat, depth + 1));
    };
    categories.forEach((cat) => traverse(cat, 0));
    return flatList;
  };

  const flattenAndSanitizeCategories = (categories) => {
    const flatList = [];
    const traverse = (cat, depth = 0) => {
      if (cat.id !== id && cat.filters && cat.filters.length > 0) {
        flatList.push({ value: cat.id, label: `${'.'.repeat(depth * 3)}${cat.name}` });
      }
      cat.subcats?.forEach((subcat) => traverse(subcat, depth + 1));
    };
    categories.forEach((cat) => traverse(cat, 0));
    return flatList;
  };

  const validateForm = () => {
    let valid = true;

    if (catName.length < 3) {
      showToast("Titel muss mindestens 3 Zeichen lang sein.", 'error');
      setCatNameError(true);
      valid = false;
    } else {
      setCatNameError(false);
    }

    if (!catIconUrl.match(/\.(svg|png)$/)) {
      showToast("Foto-URL führt nicht zu einer svg oder png Datei.", 'error');
      setCatIconUrlError(true);
      valid = false;
    } else {
      setCatIconUrlError(false);
    }

    if (catDesc.length < 20) {
      showToast("Kategoriebeschreibung muss mindestens 20 Zeichen enthalten.", 'error');
      setCatDescError(true);
      valid = false;
    } else {
      setCatDescError(false);
    }

    if (catKeywords.length < 2) {
      showToast("Es müssen mindestens zwei Schlüsselwörter angegeben werden.", 'error');
      setCatKeywordsError(true);
      valid = false;
    } else {
      setCatKeywordsError(false);
    }

    if (catSlug.trim().length < 1) {
      showToast("Slug darf nicht leer sein.", 'error');
      setCatSlugError(true);
      valid = false;
    } else {
      const slug = catSlug.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
      setCatSlug(slug);
      setCatSlugError(false);
    }

    return valid;
  };

  const createCategory = async () => {
    setIsCreatingCategory(true);
    if (validateForm()) {

      try {
        const { response, error } = isEditing ? await categoriesController.editCategory(generateJson(), id) : await categoriesController.createCategory(generateJson());
        if (error) {
          showToast(error, 'error');
        } else if (response) {
          showToast(isEditing ? "Kategorie aktualisiert!" : "Kategorie erstellt!", 'success');
          navigate(`/categories/edit?id=${response.category.id}`)
        } else {
          showToast("Unexpected response received.", 'error');
        }
      } catch (error) {
        showToast("Error creating category.", 'error');
      } finally {
        setIsCreatingCategory(false);
      }
    } else {
      setIsCreatingCategory(false);
    }
  };

  const handleUploadClick = () => {
    window.open('/mediacenter', '_blank');
  };

  const addFilter = () => {
    const filterToAdd = filtersRef.current.find((filter) => filter.id === filterVal);

    if (filterToAdd && !selectedFilters.some((filter) => filter.id === filterVal)) {
      setSelectedFilters((prev) => [
        ...prev,
        { ...filterToAdd, order: selectedFilters.length + 1, mandatory: false, excludes: [] },
      ]);
      setFilterVal(null);
    } else {
      showToast("Dieser Filter wurde bereits hinzugefügt oder existiert nicht.", 'error');
    }
  };

  const setExcludes = useCallback((filterId, newExcludes) => {
    setSelectedFilters((prev) =>
      prev.map((f) => (f.id === filterId ? { ...f, excludes: newExcludes } : f))
    );
  }, [setSelectedFilters]);

  const removeFilter = useCallback((filterId) => {
    setSelectedFilters((prev) => prev.filter((filter) => filter.id !== filterId));
  }, [setSelectedFilters]);

  const toggleMandatory = useCallback((filterId) => {
    setSelectedFilters((prev) =>
      prev.map((filter) =>
        filter.id === filterId ? { ...filter, mandatory: !filter.mandatory } : filter
      )
    );
  }, [setSelectedFilters]);

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      setSelectedFilters((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  const generateJson = () => {
    return {
      category: {
        id: id ? parseInt(id, 10) : null,
        parentId: catVal ? parseInt(catVal, 10) : null,
        title: catName,
        iconUrl: catIconUrl,
        description: catDesc,
        keywords: catKeywords,
        slug: catSlug,
      },
      filters: selectedFilters.map((filter, index) => ({
        id: parseInt(filter.id, 10),
        isMandatory: filter.mandatory,
        order: index + 1,
        exceptions: filter.excludes.map((exception) => ({
          id: exception.filterId ? parseInt(exception.filterId, 10) : null,
          optId: exception.option ? parseInt(exception.option, 10) : null,
          actionType: exception.action,
          defOptId: exception.defaultOption ? parseInt(exception.defaultOption, 10) : null,
        })),
      })),
    };
  };

  const SortableFilterItem = React.memo(
    ({ filter, excludes, setExcludes, onRemove, toggleMandatory, availableFilters }) => {
      const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: filter.id,
        modifiers: [restrictToVerticalAxis],
      });

      const handleAddExclude = () => {
        setExcludes([...excludes, { filterId: null, option: null, action: null, defaultOption: null }]);
      };

      const handleRemoveExclude = (index) => {
        setExcludes(excludes.filter((_, i) => i !== index));
      };

      const handleExcludeChange = (index, key, value) => {
        const updated = [...excludes];
        updated[index] = { ...updated[index], [key]: value };
        setExcludes(updated);
      };

      const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition || 'none',
        opacity: isDragging ? 0.5 : 1,
        cursor: isDragging ? 'grabbing' : 'default',
      };

      const mainFilterOptions = filter.options?.map((opt) => ({
        id: opt.id,
        name: opt.value,
      })) || [];

      return (
        <div ref={setNodeRef} style={style} className="filter-item flex flex-col p-1">
          <div className="flex items-center gap-2">
            <Icon
              className="text-default-400 cursor-grab"
              icon="solar:maximize-square-2-outline"
              width={20}
              {...listeners}
              {...attributes}
            />
            <Icon className="text-default-400" icon="solar:eye-outline" width={20} />
            <Icon
              className="text-default-400"
              icon="solar:trash-bin-minimalistic-outline"
              width={20}
              onClick={() => onRemove(filter.id)}
            />
            <span
              style={{
                width: '200px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              title={filter.name}
            >
              {filter.name}
            </span>
            <span
              style={{
                width: '60px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
              title={filter.id}
            >
              ID: {filter.id}
            </span>
            <Checkbox isSelected={filter.mandatory} onChange={() => toggleMandatory(filter.id)}>
              Pflicht
            </Checkbox>
            {filter.mandatory && (
              <Button size="sm" color="primary" variant="ghost" onClick={handleAddExclude}>
                Ausnahme hinzufügen
              </Button>
            )}
          </div>

          {filter.mandatory && (
            <div className="flex flex-col">
              {excludes.map((exclude, index) => {
                const selectedFilter = availableFilters.find((f) => String(f.id) === String(exclude.filterId));
                const isExcludeFilterBoolean = selectedFilter?.type === 'boolean';

                const options = isExcludeFilterBoolean
                  ? ['wahr', 'falsch']
                  : (selectedFilter?.options || []).map((opt) => ({
                    id: opt.id,
                    name: opt.value,
                  }));

                const actions = [
                  { id: 'hide', name: 'Filter verstecken' },
                  { id: 'make_optional', name: 'Pflicht entfernen' },
                  { id: 'set_default', name: 'Standard setzen' },
                ];

                return (
                  <div key={index} className="flex items-center mt-2 gap-2">
                    <Autocomplete
                      variant="bordered"
                      placeholder="Filter wählen..."
                      defaultItems={availableFilters.map((f) => ({ id: f.id, name: f.name }))}
                      selectedKey={exclude.filterId || ''}
                      onSelectionChange={(value) => handleExcludeChange(index, 'filterId', value)}
                      listboxProps={{
                        emptyContent: 'Keine Filter.',
                      }}
                      size="sm"
                    >
                      {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                    </Autocomplete>

                    {exclude.filterId && (
                      <>
                        <Icon
                          className="text-default-400 cursor-pointer w-12"
                          icon="solar:arrow-right-outline"
                          width={20}
                          height={20}
                        />
                        <Autocomplete
                          variant="bordered"
                          placeholder="Option wählen..."
                          defaultItems={options}
                          selectedKey={exclude.option || ''}
                          onSelectionChange={(value) => handleExcludeChange(index, 'option', value)}
                          listboxProps={{
                            emptyContent: 'Keine Optionen.',
                          }}
                          size="sm"
                        >
                          {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                        </Autocomplete>
                        <Icon
                          className="text-default-400 cursor-pointer w-12"
                          icon="solar:arrow-right-outline"
                          width={20}
                          height={20}
                        />
                        <Autocomplete
                          variant="bordered"
                          placeholder="Aktion wählen..."
                          defaultItems={actions}
                          selectedKey={exclude.action || ''}
                          onSelectionChange={(value) => handleExcludeChange(index, 'action', value)}
                          listboxProps={{
                            emptyContent: 'Keine Aktionen.',
                          }}
                          size="sm"
                        >
                          {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                        </Autocomplete>
                        {exclude.action === 'set_default' && (
                          <>
                            <Icon
                              className="text-default-400 cursor-pointer w-12"
                              icon="solar:arrow-right-outline"
                              width={20}
                              height={20}
                            />
                            <Autocomplete
                              variant="bordered"
                              placeholder="Standard wählen..."
                              defaultItems={mainFilterOptions}
                              selectedKey={exclude.defaultOption || ''}
                              onSelectionChange={(value) => handleExcludeChange(index, 'defaultOption', value)}
                              listboxProps={{
                                emptyContent: 'Keine Optionen.',
                              }}
                              size="sm"
                            >
                              {(item) => <AutocompleteItem key={item.id}>{item.name}</AutocompleteItem>}
                            </Autocomplete>
                          </>
                        )}
                      </>
                    )}
                    <Icon
                      className="text-default-400 cursor-pointer w-12"
                      icon="solar:trash-bin-minimalistic-outline"
                      width={20}
                      height={20}
                      onClick={() => handleRemoveExclude(index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    },
    (prevProps, nextProps) => {
      return (
        prevProps.filter === nextProps.filter &&
        prevProps.excludes === nextProps.excludes &&
        prevProps.availableFilters === nextProps.availableFilters
      );
    }
  );

  return (
    <div className="w-full flex-1">
      <Link to="categories">
        <Icon
          className="text-default-400 absolute top-4 right-4 cursor-pointer z-50"
          icon="solar:close-square-bold"
          width={28}
          height={28}
        />
      </Link>

      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">
          {isEditing ? 'Kategorie bearbeiten' : 'Kategorie erstellen'}
        </h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Erstelle oder bearbeite Kategoriedaten und Filterregeln für diese Kategorie.
      </h2>

      {loading ? (
        <Spinner />
      ) : (
        <div className="mt-4 flex flex-col space-y-4">
          <h4 className="text-medium font-medium">Kategoriedaten</h4>
          <Autocomplete
            variant="bordered"
            label="Oberkategorie"
            isLoading={loading}
            defaultItems={flattenCategories(categoriesRef.current)}
            size='md'
            placeholder='Bitte auswählen'
            selectedKey={catVal}
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:layers-minimalistic-outline"
                width={18}
              />
            }
            onSelectionChange={setCatVal}
            listboxProps={{
              emptyContent: 'Keine Kategorien.'
            }}
          >
            {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
          </Autocomplete>

          <div className="flex justify-between items-center">
            <Input
              variant="bordered"
              type="text"
              size='md'
              label="Titel"
              value={catName}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={catNameError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:text-square-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setCatName(e.target.value);
                setCatNameError(false);
              }}
            />
            <Spacer x={2} />
            <Input
              variant="bordered"
              size='md'
              type="text"
              label="Icon-URL"
              value={catIconUrl}
              defaultValue=""
              labelPlacement="inside"
              isInvalid={catIconUrlError}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:gallery-outline"
                  width={18}
                />
              }
              onChange={(e) => {
                setCatIconUrl(e.target.value);
                setCatIconUrlError(false);
              }}
            />
            <Spacer x={2} />
            <Tooltip showArrow={true} content="Mediacenter öffnen">
              <Button isIconOnly color="primary" variant='ghost' aria-label="MC" size='lg' radius='full' onClick={handleUploadClick}>
                <Icon
                  className="pointer-events-none"
                  height={18}
                  icon="solar:upload-minimalistic-outline"
                  width={18}
                />
              </Button>
            </Tooltip>
          </div>
          <Textarea
            variant="bordered"
            type="text"
            size='md'
            label="Beschreibung"
            value={catDesc}
            defaultValue=""
            labelPlacement="inside"
            isInvalid={catDescError}
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:document-text-outline"
                width={18}
              />
            }
            onChange={(e) => {
              setCatDesc(e.target.value);
              setCatDescError(false);
            }}
          />
          <Input
            variant="bordered"
            type="text"
            size='md'
            label="Keywords (mit Komma trennen)"
            value={catKeywords.join(', ')}
            defaultValue=""
            labelPlacement="inside"
            isInvalid={catKeywordsError}
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:target-outline"
                width={18}
              />
            }
            onChange={(e) => {
              const keywords = e.target.value.split(',').map(keyword => keyword.trim());
              setCatKeywords(keywords);
            }}
          />
          <Input
            variant="bordered"
            size='md'
            type="text"
            label="Slug (URL-Name)"
            value={catSlug}
            defaultValue=""
            labelPlacement="inside"
            isInvalid={catSlugError}
            startContent={
              <Icon
                className="text-default-400 pointer-events-none"
                height={18}
                icon="solar:link-round-angle-outline"
                width={18}
              />
            }
            onChange={(e) => {
              setCatSlug(e.target.value);
              setCatSlugError(false);
            }}
          />
          <Divider />
          <h4 className="text-medium font-medium">Filterkonfiguration</h4>
          <div className="flex justify-between items-center">
            <Autocomplete
              variant="bordered"
              label="Aus Kategorie importieren..."
              defaultItems={flattenAndSanitizeCategories(categoriesRef.current)}
              size='md'
              placeholder='Bitte auswählen'
              selectedKey={catVal}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:layers-minimalistic-outline"
                  width={18}
                />
              }
              onSelectionChange={setCatVal}
              listboxProps={{
                emptyContent: 'Keine Kategorien.'
              }}
            >
              {(item) => <AutocompleteItem key={item.value}>{item.label}</AutocompleteItem>}
            </Autocomplete>
            <Spacer x={2} />
            <Button color="primary" variant='ghost' size='lg'>
              Importieren
            </Button>
          </div>
          <div className="flex justify-between items-center">
            <Autocomplete
              variant="bordered"
              label="Filter wählen"
              defaultItems={filtersRef.current.filter(
                (filter) => !selectedFilters.some((selected) => selected.id === filter.id)
              )}
              size="md"
              placeholder="Bitte auswählen"
              selectedKey={filterVal}
              startContent={
                <Icon
                  className="text-default-400 pointer-events-none"
                  height={18}
                  icon="solar:filter-outline"
                  width={18}
                />
              }
              listboxProps={{ emptyContent: 'Keine Filter.' }}
              onSelectionChange={setFilterVal}
            >
              {(item) => <AutocompleteItem key={item.value}>{item.name}</AutocompleteItem>}
            </Autocomplete>
            <Spacer x={2} />
            <Button color="primary" size='lg' variant='ghost' onPress={addFilter}>
              Hinzufügen
            </Button>
          </div>

          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={selectedFilters.map((filter) => filter.id)} strategy={verticalListSortingStrategy}>
              {selectedFilters.map((filter) => (
                <SortableFilterItem
                  key={filter.id}
                  filter={filter}
                  excludes={filter.excludes}
                  setExcludes={(newExcludes) => setExcludes(filter.id, newExcludes)}
                  availableFilters={selectedFilters.filter((f) => f.id !== filter.id)}
                  onRemove={removeFilter}
                  toggleMandatory={toggleMandatory}
                />
              ))}
            </SortableContext>
          </DndContext>
          <div className="flex justify-end">
            <Button
              isLoading={isCreatingCategory}
              color="primary"
              size="lg"
              className="py-1 px-3"
              onPress={createCategory}
            >
              {isEditing ? 'Kategorie bearbeiten' : 'Kategorie erstellen'}
            </Button>
          </div>
          <Divider />
          <h4 className="text-medium font-medium">Dev-Details</h4>
          <div className="flex justify-between items-center">
            <JsonView src={generateJson()} />
          </div>
        </div>)
      }
    </div >
  );
};

export default CategoryEditor;