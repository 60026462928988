import { Icon } from '@iconify/react/dist/iconify.js';
import { User } from '@nextui-org/react';
import React from 'react';

interface FolderComponentProps {
    name: string;
    onClick: () => void;
}

const FolderComponent: React.FC<FolderComponentProps> = ({ name, onClick }) => {
    return (
        <User
            name={name}
            description="Ordner"
            onClick={onClick}
            avatarProps={{
                src: "",
                fallback: <Icon height={18} icon="solar:folder-with-files-outline" width={18} />
            }}
            style={{ cursor: 'pointer' }}
        />
    );
};

export default FolderComponent;
