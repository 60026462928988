import AdminUser from "./adminUser";

interface MaintenanceHistoryItemParams {
  id: number;
  created_at: string;
  ios: string;
  android: string;
  maintenance_start?: string | null;
  maintenance_end?: string | null;
  maintenance_message?: string | null;
  created_by: any;
  baseurl: string;
}

class MaintenanceHistoryItem {
  id: number;
  created_at: string;
  ios: string;
  android: string;
  maintenance_start: string | null;
  maintenance_end: string | null;
  maintenance_message: string | null;
  created_by: AdminUser;
  baseurl: string;

  constructor({
    id,
    created_at,
    ios,
    android,
    maintenance_start = null,
    maintenance_end = null,
    maintenance_message = null,
    created_by,
    baseurl,
  }: MaintenanceHistoryItemParams) {
    this.id = id;
    this.created_at = created_at;
    this.ios = ios;
    this.android = android;
    this.maintenance_start = maintenance_start;
    this.maintenance_end = maintenance_end;
    this.maintenance_message = maintenance_message;
    this.created_by = new AdminUser(created_by);
    this.baseurl = baseurl;
  }

  static fromJson(json: MaintenanceHistoryItemParams): MaintenanceHistoryItem {
    return new MaintenanceHistoryItem(json);
  }
}

export default MaintenanceHistoryItem;
