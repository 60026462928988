"use client";

import { useEffect, useState } from "react";
import {
  User,
  Badge,
  Avatar,
  Button,
  ScrollShadow,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Input,
  Spacer,
  Image,
  useDisclosure,
  Kbd,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";
import NotificationsCard from "./notifications-card";
import Sidebar from "./sidebar";
import authController from "../../api/authController";
import useToast from "../../hooks/useToast";
import SidebarDrawer from "./sidebar-drawer";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import Dashboard from "../Dashboard";
import WaWi from "../WaWi";
import Offers from "../Offers";
import Users from "../Users";
import Reports from "../Reports";
import DeletionRequests from "../DeletionRequests";
import FAQs from "../FAQs";
import Support from "../Support";
import Mediacenter from "../Mediacenter";
import AppMaintenance from "../AppMaintenance";
import Admin from "../Admin";
import Demo from "../Demo";
import ErrorBoundary from "../../helpers/errorBoundary";
import userService from "../../services/userService";
import appLogo from '../../assets/svg/happyshop.svg';
import CMS from "../CMS";
import Returns from "../Returns";
import Config from "../config/Config";
import CategoryPage from "../category/Page";
import FilterPage from "../filter/Page";

export default function AppHome() {
  const navigate = useNavigate();
  const location = useLocation();

  const sidebarItems = [
    {
      key: "main",
      title: "",
      items: [
        {
          key: "home",
          icon: "solar:home-2-linear",
          title: "Home",
          component: "Dashboard",
        },
        {
          key: "wawi",
          icon: "solar:wallet-money-linear",
          title: "WaWi",
          component: "WaWi",
        },
        {
          key: "offers",
          icon: "solar:tag-price-linear",
          title: "Angebote",
          component: "Offers",
        },
        {
          key: "users",
          icon: "solar:users-group-two-rounded-linear",
          title: "Nutzer",
          component: "Users",
        },
        {
          key: "returns",
          icon: "solar:sad-square-outline",
          title: "Rücksendungen",
          component: "Returns",
        },
      ],
    },
    {
      key: "structure",
      title: "Struktur",
      items: [
        {
          key: "categories",
          icon: "solar:layers-minimalistic-linear",
          title: "Kategorien",
          component: "Categories",
        },
        {
          key: "filters",
          icon: "solar:filter-linear",
          title: "Filter",
          component: "Filters",
        },
      ],
    },
    {
      key: "support-m",
      title: "Support",
      items: [
        {
          key: "support",
          icon: "solar:chat-round-line-linear",
          title: "Support",
          component: "Support",
        },
        {
          key: "reports",
          icon: "solar:shield-warning-linear",
          title: "Meldungen",
          component: "Reports",
        },
        {
          key: "deletionrequests",
          icon: "solar:user-block-linear",
          title: "Löschanträge",
          component: "DeletionRequests",
        },
      ],
    },
    {
      key: "content",
      title: "Contentverwaltung",
      items: [
        {
          key: "mediacenter",
          icon: "solar:gallery-wide-linear",
          title: "Mediacenter",
          component: "Mediacenter",
        },
        {
          key: "cms",
          icon: "solar:globus-outline",
          title: "CMS",
          component: "CMS",
        },
        {
          key: "faqs",
          icon: "solar:question-square-linear",
          title: "FAQs",
          component: "FAQs",
        },
      ],
    },
    {
      key: "management",
      title: "Verwaltung",
      items: [
        {
          key: "config",
          icon: "solar:tuning-2-outline",
          title: "Config",
          component: "Config",
        },
        {
          key: "appmaintenance",
          icon: "solar:sleeping-square-linear",
          title: "App-Wartung",
          component: "AppMaintenance",
        },
        {
          key: "admin",
          icon: "solar:settings-linear",
          title: "Admin",
          component: "Admin",
        },
      ],
    },
  ];

  const [currentModule, setCurrentModule] = useState('home');
  const [selectedKey, setSelectedKey] = useState('home');
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { showToast } = useToast();
  const [user, setUser] = useState(userService.getUser());

  const handleRefreshUser = () => {
    authController.refreshUser().then(response => {
      if (response.user) {
        showToast("Aktualisierung erfolgreich!", 'success');
        setUser(response.user);
      } else {
        showToast(response.error || "Fehler bei der Aktualisierung.", 'error');
      }
    });
  };

  const logout = () => {
    authController.logoutUser();
    navigate("/auth");
    showToast("Logout erfolgreich!", "success");
  };

  useEffect(() => {
    const moduleFromPath = decodeURIComponent(location.pathname.split('/')[1].toLowerCase());
    const matchedItem = sidebarItems.reduce((acc, item) => {
      acc.push(item);
      if (item.items) {
        item.items.forEach(subItem => {
          acc.push(subItem);
        });
      }
      return acc;
    }, []).find(item => item.key.toLowerCase() === moduleFromPath);

    if (matchedItem) {
      setCurrentModule(matchedItem.component);
      setSelectedKey(matchedItem.key);
    } else {
      setCurrentModule("Dashboard");
      setSelectedKey("home");
    }
  }, [location]);


  const handleSetCurrentModule = (moduleKey) => {
    const matchedItem = sidebarItems.reduce((acc, item) => {
      acc.push(item);
      if (item.items) {
        item.items.forEach(subItem => {
          acc.push(subItem);
        });
      }
      return acc;
    }, []).find(item => item.key === moduleKey);
    if (matchedItem) {
      navigate(`/${encodeURIComponent(matchedItem.key)}`);
      setCurrentModule(matchedItem.component);
    } else {
      navigate('/');
      setCurrentModule("Dashboard");
    }
  };

  const renderModule = () => {
    switch (currentModule) {
      case "Home":
        return <Dashboard />;
      case "WaWi":
        return <WaWi />;
      case "Offers":
        return <Offers />;
      case "Users":
        return <Users />;
      case "Categories":
        return <CategoryPage />;
      case "Filters":
        return <FilterPage />;
      case "Reports":
        return <Reports />;
      case "DeletionRequests":
        return <DeletionRequests />;
      case "FAQs":
        return <FAQs />;
      case "CMS":
        return <CMS />;
      case "Support":
        return <Support />;
      case "Mediacenter":
        return (
          <Routes>
            <Route path="mediacenter/*" element={<MediacenterWrapper />} />
          </Routes>
        );
      case "AppMaintenance":
        return <AppMaintenance />;
      case "Admin":
        return <Admin />;
      case "Returns":
        return <Returns />;
      case "Config":
        return <Config />;
      default:
        return <Demo />;
    }
  };

  const content = (
    <div className="relative flex h-full w-72 flex-col p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2 px-2">
          <div className="flex h-8 w-8 items-center justify-center rounded-full">
            <Image
              height={20}
              alt="HappyShop Logo"
              radius="none"
              src={appLogo}
            />
          </div>
          <span className="font-medium">Dashboard</span>
        </div>
        <div className="flex items-center justify-end">
          <Dropdown showArrow placement="bottom-start">
            <DropdownTrigger>
              <Button disableRipple isIconOnly className="-mr-1" radius="full" variant="light">
                <Avatar className="h-6 w-6 cursor-pointer" name={`${user.firstname} ${user.lastname}`} src={user.picture} />
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Custom item styles" disabledKeys={["profile"]}>
              <DropdownSection showDivider aria-label="Profile & Actions">
                <DropdownItem
                  key="profile"
                  isReadOnly
                  className="h-14 gap-2 opacity-100"
                  textValue="Signed in as"
                >
                  <User
                    avatarProps={{ size: "sm", src: user.picture, imgProps: { className: "transition-none" } }}
                    classNames={{ name: "text-default-600", description: "text-default-500" }}
                    description={user.role}
                    name={`${user.firstname} ${user.lastname}`}
                  />
                </DropdownItem>
                <DropdownItem
                  onClick={handleRefreshUser}
                  description="User updaten"
                  startContent={<Icon className="text-default-500" icon="solar:refresh-linear" width={18} />}
                >
                  Aktualisieren
                </DropdownItem>
              </DropdownSection>

              <DropdownSection aria-label="Logout">
                <DropdownItem
                  onClick={logout}
                  description="Schönen Feierabend"
                  startContent={<Icon className="text-default-500" icon="solar:logout-3-linear" width={18} />}
                >
                  Logout
                </DropdownItem>
              </DropdownSection>
            </DropdownMenu>
          </Dropdown>
          <Popover offset={12} placement="bottom-start">
            <PopoverTrigger>
              <Button disableRipple isIconOnly className="overflow-visible" radius="full" variant="light">
                <Badge color="primary" content="0" showOutline={false} size="md">
                  <Icon className="text-default-500" icon="solar:bell-linear" width={22} />
                </Badge>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="max-w-[90vw] p-0 sm:max-w-[380px]">
              <NotificationsCard className="w-full shadow-none" />
            </PopoverContent>
          </Popover>
        </div>
      </div>

      <Spacer y={8} />

      <div className="flex flex-col gap-y-2">
        <Input
          fullWidth
          aria-label="search"
          labelPlacement="outside"
          placeholder='Suche...'
          variant="bordered"
          endContent={<Kbd keys={["command"]}>K</Kbd>}
          startContent={<Icon className="text-default-500 [&>g]:stroke-[2px]" icon="solar:magnifer-linear" width={18} />}
        />
      </div>

      <ScrollShadow className="-mr-6 h-full max-h-full py-6 pr-6">
        <Sidebar
          items={sidebarItems}
          defaultSelectedKey={selectedKey}
          onSelect={handleSetCurrentModule}
        />
        <Spacer y={8} />
      </ScrollShadow>
    </div>
  );

  return (
    <div className="flex h-dvh w-full">
      <SidebarDrawer className="flex-none border-r-small border-divider" isOpen={isOpen} onOpenChange={onOpenChange}>
        {content}
      </SidebarDrawer>
      <div className="flex w-full flex-col gap-y-4 sm:max-w-[calc(100%_-_288px)]">
        <header className="flex h-16 min-h-16 items-center justify-between gap-2 overflow-x-scroll rounded-medium px-4 py-2 sm:hidden">
          <div className="flex max-w-full items-center gap-2">
            <Button
              isIconOnly
              className="flex"
              size="sm"
              variant="light"
              onPress={onOpen}
            >
              <Icon
                className="text-default-500"
                height={24}
                icon="solar:hamburger-menu-outline"
                width={24}
              />
            </Button>
            <h2 className="truncate text-medium font-medium text-default-700">Header-Navi</h2>
          </div>
          <div>
          </div>
        </header>
        <main className="flex h-full overflow-hidden">
          <div
            className="flex h-full w-full flex-col rounded-medium overflow-y-auto p-4"
          >
            {renderModule()}
          </div>
        </main>
      </div>
    </div>
  );
}

function MediacenterWrapper() {
  const { '*': folderPath } = useParams();
  const navigate = useNavigate();
  return <ErrorBoundary><Mediacenter folderPath={folderPath || 'ressources'} navigate={navigate} /></ErrorBoundary>;
}
