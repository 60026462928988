import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Input,
  Button,
  DropdownTrigger,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Chip,
  User,
  Spinner,
} from "@nextui-org/react";
import categoriesController from '../../api/categoryController';
import useToast from '../../hooks/useToast';
import { Icon } from '@iconify/react/dist/iconify.js';
import { Link, useNavigate } from 'react-router-dom';

const statusColorMap = {
  aktiv: "success",
  deaktiviert: "danger",
};
const INITIAL_VISIBLE_COLUMNS = ["name", "activeOffers", "parent_id", "status", "actions"];

const adaptCategoryData = (category) => ({
  id: category.id,
  icon: category.icon,
  name: category.name,
  description: category.description,
  activeOffers: category.activeOffers,
  parent_id: category.parent_id,
  keywords: category.keyword,
  status: category.deactivated ? 'deaktiviert' : 'aktiv',
  subcats: category.subcats ? category.subcats.map(adaptCategoryData) : [],
});

const CategoryList = () => {
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const { response, error } = await categoriesController.getAllCategories();
      if (error) {
        console.error(error);
      } else {
        setCategories(response.map(adaptCategoryData));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const [filterValue, setFilterValue] = useState("");
  const [selectedKeys, setSelectedKeys] = useState(new Set([]));
  const [visibleColumns, setVisibleColumns] = useState(new Set(INITIAL_VISIBLE_COLUMNS));
  const [statusFilter, setStatusFilter] = React.useState("all");
  const [sortDescriptor, setSortDescriptor] = useState({
    column: "id",
    direction: "ascending",
  });

  const hasSearchFilter = Boolean(filterValue);
  const headerColumns = useMemo(() => {
    if (visibleColumns === "all") return columns;
    return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
  }, [visibleColumns]);
  const filteredItems = useMemo(() => {
    let filteredCategories = [...categories];

    if (hasSearchFilter) {
      filteredCategories = filteredCategories.filter((category) =>
        category.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
    }

    if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
      filteredCategories = filteredCategories.filter((category) =>
        Array.from(statusFilter).includes(category.status),
      );
    }

    return filteredCategories;
  }, [categories, filterValue, statusFilter, hasSearchFilter]);
  const items = useMemo(() => {
    return filteredItems.slice();
  }, [filteredItems]);
  const sortedItems = useMemo(() => {
    return [...items].sort((a, b) => {
      const first = a[sortDescriptor.column];
      const second = b[sortDescriptor.column];
      const cmp = first < second ? -1 : first > second ? 1 : 0;

      return sortDescriptor.direction === "descending" ? -cmp : cmp;
    });
  }, [sortDescriptor, items]);

  const toggleCategoryStatus = useCallback(async (categoryId) => {
    const { response, error } = await categoriesController.deactivateCategory(categoryId);
    if (error) {
      showToast(error, 'error');
      console.error("Error deactivating category:", error);
    } else {
      showToast("Sichtbarkeit aktualisiert!", 'success');
      console.log("Category de/activated successfully:", response);
      fetchCategories();
    }
  }, [showToast]);

  const deleteCategory = useCallback(async (categoryId) => {
    if (window.confirm("Möchtest Du diese Kategorie wirklich löschen?")) {
      const { response, error } = await categoriesController.deleteCategory(categoryId);
      if (error) {
        showToast(error, 'error');
        console.error("Error deleting category:", error);
      } else {
        showToast("Erfolgreich gelöscht!", 'success');
        console.log("Category deleted successfully:", response);
        fetchCategories();
      }
    }
  }, [showToast]);

  const renderCell = useCallback((category, columnKey) => {
    const cellValue = category[columnKey];
    switch (columnKey) {
      case "name":
        return (
          <User
            avatarProps={{
              src: category.icon,
              isBordered: category.parent_id === null,
              size: category.parent_id == null ? 'md' : 'sm'
            }}
            description={category.description}
            name={category.name}
          >
            <p>{category.description}</p>
          </User>
        );
      case "status":
        return (
          <Chip color={statusColorMap[category.status]} size="sm" variant="flat">
            {category.status}
          </Chip>
        );
      case "actions":
        return (
          <div className="relative flex justify-end items-center gap-2">
            <Dropdown className="w-[250px]">
              <DropdownTrigger>
                <Button isIconOnly size="sm" variant="light">
                  <Icon
                    className='text-default-300'
                    height={18}
                    icon="solar:menu-dots-bold"
                    width={18}
                  />
                </Button>
              </DropdownTrigger>
              <DropdownMenu>
                <DropdownItem
                  description="Daten & Filter"
                  startContent={<Icon
                    height={18}
                    icon="solar:pen-2-outline"
                    width={18}
                  />}
                  onClick={() => navigate(`/categories/edit?id=${category.id}`)}
                >
                  Bearbeiten
                </DropdownItem>
                {category.status === "aktiv" ? (
                  <DropdownItem
                    description="Inaktiv schalten"
                    startContent={
                      <Icon
                        height={18}
                        icon="solar:pause-outline"
                        width={18}
                      />}
                    onClick={() => toggleCategoryStatus(category.id)}
                  >
                    Deaktivieren
                  </DropdownItem>
                ) : (
                  <DropdownItem
                    description="Aktiv schalten"
                    startContent={
                      <Icon
                        height={18}
                        icon="solar:play-outline"
                        width={18}
                      />
                    }
                    onClick={() => toggleCategoryStatus(category.id)}
                  >
                    Aktivieren
                  </DropdownItem>
                )}
                <DropdownItem
                  description="Unwiederruflich"
                  startContent={<Icon
                    height={18}
                    icon="solar:trash-bin-minimalistic-outline"
                    width={18}
                  />}
                  onClick={() => deleteCategory(category.id)}
                >
                  Löschen
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        );
      default:
        return cellValue;
    }
  }, [deleteCategory, toggleCategoryStatus]);

  const onSearchChange = useCallback((value) => {
    if (value) {
      setFilterValue(value);
    } else {
      setFilterValue("");
    }
  }, []);

  const onClear = useCallback(() => {
    setFilterValue("")
  }, [])

  const topContent = useMemo(() => {
    return (
      <div className="flex flex-col gap-4">
        <div className="flex justify-between gap-3 items-end">
          <Input
            variant="bordered"
            isClearable
            className="w-full sm:max-w-[44%]"
            placeholder="Suche nach Titel..."
            startContent={<Icon
              className="text-default-500"
              height={18}
              icon="solar:magnifer-outline"
              width={18}
            />}
            value={filterValue}
            onClear={() => onClear()}
            onValueChange={onSearchChange}
          />
          <div className="flex gap-3">
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<Icon
                  className="text-small"
                  height={18}
                  icon="solar:alt-arrow-down-outline"
                  width={18}
                />} variant="flat">
                  Status
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={statusFilter}
                selectionMode="multiple"
                onSelectionChange={setStatusFilter}
              >
                {statusOptions.map((status) => (
                  <DropdownItem key={status.uid}>
                    {status.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Dropdown>
              <DropdownTrigger className="hidden sm:flex">
                <Button endContent={<Icon
                  className="text-small"
                  height={18}
                  icon="solar:alt-arrow-down-outline"
                  width={18}
                />} variant="flat">
                  Felder
                </Button>
              </DropdownTrigger>
              <DropdownMenu
                disallowEmptySelection
                aria-label="Table Columns"
                closeOnSelect={false}
                selectedKeys={visibleColumns}
                selectionMode="multiple"
                onSelectionChange={setVisibleColumns}
              >
                {columns.map((column) => (
                  <DropdownItem key={column.uid}>
                    {column.name}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
            <Link to="/categories/create">
              <Button color="primary">
                Kategorie erstellen
              </Button>
            </Link>
          </div>
        </div>
      </div>
    );
  }, [
    filterValue,
    statusFilter,
    visibleColumns,
    onSearchChange,
    onClear,
  ]);

  const renderRows = useCallback((categories) => {
    const rows = [];

    const renderCategory = (category, level = 0) => {
      rows.push(
        <TableRow key={category.id} style={{ paddingLeft: `${level * 20}px` }}>
          {headerColumns.map((column) => (
            <TableCell key={column.uid}>{renderCell(category, column.uid)}</TableCell>
          ))}
        </TableRow>
      );

      if (category.subcats && category.subcats.length > 0) {
        category.subcats.forEach((subcat) => renderCategory(subcat, level + 1));
      }
    };

    categories.forEach((category) => {
      if (!category.category_id) {
        renderCategory(category);
      }
    });

    return rows;
  }, [headerColumns, renderCell]);

  return (
    <div className="w-full flex-1">
      <div className="flex items-center gap-x-3">
        <h1 className="text-3xl font-bold leading-9 text-default-foreground">Kategorieverwaltung</h1>
      </div>
      <h2 className="mt-2 text-small text-default-500 mb-6">
        Bearbeite und erstelle Kategorien für neuen Content.
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <Table
          aria-label="Categories Table"
          isHeaderSticky
          bottomContentPlacement="outside"
          selectedKeys={selectedKeys}
          selectionMode="none"
          sortDescriptor={sortDescriptor}
          topContent={topContent}
          removeWrapper
          topContentPlacement="outside"
          onSelectionChange={setSelectedKeys}
          onSortChange={setSortDescriptor}
        >
          <TableHeader columns={headerColumns}>
            {(column) => (
              <TableColumn
                key={column.uid}
                align={column.uid === "actions" ? "center" : "start"}
                allowsSorting={column.sortable}
              >
                {column.name}
              </TableColumn>
            )}
          </TableHeader>
          <TableBody emptyContent={"Keine Kategorien gefunden"} items={sortedItems}>
            {renderRows(sortedItems)}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default CategoryList;

const columns = [
  { name: "ID", uid: "id", sortable: true },
  { name: "Kategorie", uid: "name", sortable: true },
  { name: "Aktive Angebote", uid: "activeOffers", sortable: true },
  { name: "Überkategorie", uid: "parent_id", },
  { name: "Status", uid: "status", },
  { name: "Optionen", uid: "actions" },
];

const statusOptions = [
  { name: "Aktiv", uid: "aktiv" },
  { name: "Deaktiviert", uid: "deaktiviert" },
];

export { columns, statusOptions };
