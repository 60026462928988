import React from 'react';
import { useLocation } from 'react-router-dom';
import CategoryList from './CategoryList';
import CategoryEditor from './CategoryEditor';
import CategoryDetail from './CategoryDetail';

const CategoryPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const path = location.pathname;

  if (path.startsWith('/categories/create')) {
    return <CategoryEditor />;
  }

  if (path.startsWith('/categories/edit') && id) {
    return <CategoryEditor id={id} />;
  }

  if (path.startsWith('/categories/') && id) {
    return <CategoryDetail id={id} />;
  }

  return <CategoryList />;
};

export default CategoryPage;
