import FilterCondition from "./filterCondition";
import FilterOption from "./filterOption";

interface FilterParams {
  id: number;
  filter_type: FilterType;
  name: string;
  description?: string;
  isMandatory?: boolean;
  order?: number;
  conditions?: FilterCondition[];
  options?: FilterOption[];
  createdAt?: Date;
  updatedAt?: Date;
}

class Filter {
  id: number;
  filter_type: FilterType;
  name: string;
  description: string;
  isMandatory: boolean;
  order: number;
  conditions: FilterCondition[];
  options: FilterOption[];
  createdAt: Date;
  updatedAt: Date;

  constructor({
    id,
    filter_type,
    name,
    description = '',
    isMandatory = false,
    order = 0,
    conditions = [],
    options = [],
    createdAt = new Date(),
    updatedAt = new Date(),
  }: FilterParams) {
    this.id = id;
    this.filter_type = filter_type;
    this.name = name;
    this.description = description;
    this.isMandatory = isMandatory;
    this.order = order;
    this.conditions = conditions;
    this.options = options;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export default Filter;

export enum FilterType {
  BOOLEAN = 'boolean',
  SINGLE_CHOICE = 'single_choice',
  MULTIPLE_CHOICE = 'multiple_choice',
}

export const getFilterTypeLabel = (type: FilterType): string => {
  switch (type) {
    case FilterType.BOOLEAN:
      return 'Checkbox';
    case FilterType.SINGLE_CHOICE:
      return 'Single-Choice';
    case FilterType.MULTIPLE_CHOICE:
      return 'Set (Mehrfachauswahl)';
    default:
      return 'Unknown';
  }
};