interface Gender {
    name: string;
    code: number;
    emoji: string;
    description: string;
}

const genderList: Record<number, Gender> = {
    0: {
        'name': 'Männlich',
        'code': 0,
        'emoji': '👨',
        'description': 'Männliches Geschlecht',
    },
    1: {
        'name': 'Weiblich',
        'code': 1,
        'emoji': '👩',
        'description': 'Weibliches Geschlecht',
    },
    2: {
        'name': 'Divers',
        'code': 2,
        'emoji': '🌈',
        'description': 'Diverses Geschlecht',
    },
    3: {
        'name': 'Nicht-binär',
        'code': 3,
        'emoji': '⚧',
        'description': 'Nicht-binäres Geschlecht',
    },
    4: {
        'name': 'Agender',
        'code': 4,
        'emoji': '⚪',
        'description': 'Agender Geschlecht',
    },
    5: {
        'name': 'Genderfluid',
        'code': 5,
        'emoji': '🔄',
        'description': 'Genderfluid Geschlecht',
    },
    6: {
        'name': 'Genderqueer',
        'code': 6,
        'emoji': '❓',
        'description': 'Genderqueer Geschlecht',
    },
    7: {
        'name': 'Transgender',
        'code': 7,
        'emoji': '🏳️‍⚧️',
        'description': 'Transgender Geschlecht',
    },
};

const GenderHelper = {
    get: function (genderId: number): string {
        if (genderList.hasOwnProperty(genderId)) {
            return genderList[genderId].name;
        } else {
            return '-';
        }
    }
};

export default GenderHelper;
