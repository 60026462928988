interface AdminUserParams {
    id: string;
    email: string;
    role: string;
    created_at: string;
    firstname: string;
    lastname: string;
    last_login: string | null;
    deactivated_at: string | null;
    comment: string | null;
    created_by: string;
    modules: number[];
    birthdate: string | null;
    picture: string | null;
  }
  
  class AdminUser {
    id: string;
    email: string;
    role: string;
    created_at: string;
    firstname: string;
    lastname: string;
    last_login: string | null;
    deactivated_at: string | null;
    comment: string | null;
    created_by: string;
    modules: number[];
    birthdate: string | null;
    picture: string | null;
  
    constructor({
      id,
      email,
      role,
      created_at,
      firstname,
      lastname,
      last_login,
      deactivated_at,
      comment,
      created_by,
      modules = [],
      birthdate,
      picture,
    }: AdminUserParams) {
      this.id = id;
      this.email = email;
      this.role = role;
      this.created_at = created_at;
      this.firstname = firstname;
      this.lastname = lastname;
      this.last_login = last_login;
      this.deactivated_at = deactivated_at;
      this.comment = comment;
      this.created_by = created_by;
      this.modules = modules;
      this.birthdate = birthdate;
      this.picture = picture;
    }
  
    static fromJson(json: AdminUserParams): AdminUser {
      return new AdminUser(json);
    }
  }
  
  export default AdminUser;
  