import CookieHelper from "../helpers/getCookieHelper";
import MaintenanceHistoryItem from "../models/maintenanceHistoryItem";

interface ApiResponse<T> {
  response: T | null;
  error: string | null;
}

const maintenanceController = {
  getMaintenanceHistory: function (): Promise<ApiResponse<MaintenanceHistoryItem[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const adminBearerToken = process.env.REACT_APP_ADMIN_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken || !adminBearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}maintenance`, {
        method: 'GET',
        headers,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.response) {
            const historyItems = json.response.map((item: any) => new MaintenanceHistoryItem(item));
            resolve({ response: historyItems, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected error occurred." });
          }
        })
        .catch((error) => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  saveMaintenanceConfig: function (config: any): Promise<ApiResponse<MaintenanceHistoryItem[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const adminBearerToken = process.env.REACT_APP_ADMIN_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken || !adminBearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}maintenance`, {
        method: 'POST',
        headers,
        body: JSON.stringify(config),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.response) {
            const historyItems = json.response.map((item: any) => new MaintenanceHistoryItem(item));
            resolve({ response: historyItems, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected error occurred." });
          }
        })
        .catch((error) => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },
};

export default maintenanceController;