import { supabase } from './supabaseClient';

interface StorageItem {
  id?: string;
  name: string;
  updated_at?: string;
  created_at?: string;
  last_accessed_at?: string;
  metadata?: {
    size?: number;
  };
  type?: 'file' | 'folder';
}

const fetchFolderContents = async (folderPath: string): Promise<StorageItem[]> => {
  const { data, error } = await supabase.storage.from('happyshop').list(folderPath, {
    offset: 0,
    sortBy: { column: 'name', order: 'asc' }
  });

  if (error) {
    console.error(`Error fetching storage data for folder ${folderPath}:`, error);
    return [];
  }

  const itemsWithType = data
    .filter((item) => !item.name.startsWith('.'))
    .map((item) => {
      const isFile = item.metadata && item.metadata.size !== undefined;
      
      return {
        ...item,
        type: isFile ? 'file' : 'folder'
      } as StorageItem;
    });

  return itemsWithType;
};

const createFolder = async (folderPath: string): Promise<boolean> => {
  const { error } = await supabase.storage.from('happyshop').upload(`${folderPath}/.keep`, new Blob(['']), {
    cacheControl: '3600',
    upsert: false
  });

  if (error) {
    console.error(`Error creating folder ${folderPath}:`, error);
    return false;
  }

  return true;
};

const uploadFiles = async (folderPath: string, files: File[]): Promise<boolean> => {
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const { error } = await supabase.storage
      .from('happyshop')
      .upload(`${folderPath}/${file.name}`, file);

    if (error) {
      console.error(`Error uploading file ${file.name}:`, error);
      return false;
    }
  }
  return true;
};

const deleteFile = async (filePath: string): Promise<boolean> => {
  const { error } = await supabase.storage.from('happyshop').remove([`ressources/${filePath}`]);

  if (error) {
    console.error(`Error deleting file ${filePath}:`, error);
    return false;
  }
  return true;
};

const pathExists = async (folderPath: string): Promise<boolean> => {
  const { data, error } = await supabase.storage.from('happyshop').list(folderPath);
  return !error && data && data.length > 0;
};

const mediacenterController = {
  getStorageData: async (folderPath: string = 'ressources'): Promise<StorageItem[]> => {
    try {
      const data = await fetchFolderContents(folderPath);
      return data;
    } catch (error) {
      console.error('Error in getStorageData:', error);
      return [];
    }
  },
  createFolder,
  uploadFiles,
  deleteFile,
  pathExists,
};

export default mediacenterController;