import React, { useEffect, useState, useMemo, useCallback } from 'react';
import {
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell,
    Input,
    Button,
    DropdownTrigger,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Chip,
    User,
    Pagination,
    Spinner,
} from "@nextui-org/react";
import AgeHelper from '../helpers/getAgeHelper';
import usersController from '../api/usersController';
import GenderHelper from '../helpers/getGenderHelper';

const statusColorMap = {
    aktiv: "success",
    deaktiviert: "danger",
};
const INITIAL_VISIBLE_COLUMNS = ["username", "age", "gender", "created", "actions"];

const adaptUserData = (user) => ({
    id: user.id,
    picture: user.profilePicture,
    username: `@${user.username}`,
    displayname: user.displayname,
    name: `${user.firstname} ${user.lastname}`,
    age: user.birthdate,
    gender: GenderHelper.get(user.gender),
    created: user.createdAt,
    status: user.deactivatedAt ? 'deaktiviert' : 'aktiv',
});

const Users = () => {
    // TABLE RELEVANT

    // LOAD USERS
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const { response, error } = await usersController.getAllAppUsers();
            if (error) {
                console.error(error);
            } else {
                setUsers(response.map(adaptUserData));
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    // TABLE SORTING
    const [filterValue, setFilterValue] = React.useState("");
    const [selectedKeys, setSelectedKeys] = React.useState(new Set([]));
    const [visibleColumns, setVisibleColumns] = React.useState(new Set(INITIAL_VISIBLE_COLUMNS));
    const [statusFilter, setStatusFilter] = React.useState("all");
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [sortDescriptor, setSortDescriptor] = React.useState({
        column: "created",
        direction: "descending",
    });

    const [page, setPage] = useState(1);
    const hasSearchFilter = Boolean(filterValue);
    const headerColumns = React.useMemo(() => {
        if (visibleColumns === "all") return columns;
        return columns.filter((column) => Array.from(visibleColumns).includes(column.uid));
    }, [visibleColumns]);
    const filteredItems = React.useMemo(() => {
        let filteredUsers = [...users];

        if (hasSearchFilter) {
            filteredUsers = filteredUsers.filter((user) =>
                user.username.toLowerCase().includes(filterValue.toLowerCase()),
            );
        }

        if (statusFilter !== "all" && Array.from(statusFilter).length !== statusOptions.length) {
            filteredUsers = filteredUsers.filter((user) =>
                Array.from(statusFilter).includes(user.status),
            );
        }

        return filteredUsers;
    }, [users, filterValue, statusFilter, hasSearchFilter]);
    const pages = Math.ceil(filteredItems.length / rowsPerPage);
    const items = React.useMemo(() => {
        const start = (page - 1) * rowsPerPage;
        const end = start + rowsPerPage;

        return filteredItems.slice(start, end);
    }, [page, filteredItems, rowsPerPage]);
    const sortedItems = React.useMemo(() => {
        return [...items].sort((a, b) => {
            const first = a[sortDescriptor.column];
            const second = b[sortDescriptor.column];
            const cmp = first < second ? -1 : first > second ? 1 : 0;

            return sortDescriptor.direction === "descending" ? -cmp : cmp;
        });
    }, [sortDescriptor, items]);
    const renderCell = useCallback((user, columnKey) => {
        const cellValue = user[columnKey];
        switch (columnKey) {
            case "username":
                return (
                    <User
                        avatarProps={{ src: user.picture }}
                        description={user.displayname}
                        name={user.username}
                    >
                        {user.displayname}
                    </User>
                );
            case "age":
                return user.age
                    ? `${new Date(user.age).toLocaleDateString()} (${AgeHelper.get(user.age)})`
                    : "-";

            case "gender":
                return (
                    <div className="flex flex-col">
                        <p className="text-bold text-small">{user.gender}</p>
                    </div>
                );
            case "created":
                return new Date(user.created).toLocaleString();
            case "status":
                return (
                    <Chip color={statusColorMap[user.status]} size="sm" variant="flat">
                        {user.status}
                    </Chip>
                );
            case "actions":
                return (
                    <div className="relative flex justify-end items-center gap-2">
                        <Dropdown className="w-[250px]">
                            <DropdownTrigger>
                                <Button isIconOnly size="sm" variant="light">
                                    <i className="fa-light fa-ellipsis-vertical text-default-300" style={{ textAlign: 'center' }}></i>
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu>
                                <DropdownItem
                                    description="Profildaten & Rechte"
                                    startContent={<i className="fa-light fa-user-pen" style={{ width: '18px', textAlign: 'center' }}></i>}
                                >
                                    Bearbeiten
                                </DropdownItem>
                                <DropdownItem
                                    description="Login sperren"
                                    startContent={<i className="fa-light fa-ban" style={{ width: '18px', textAlign: 'center' }}></i>}
                                >
                                    Deaktivieren
                                </DropdownItem>
                                <DropdownItem
                                    description="Alle Daten löschen"
                                    startContent={<i className="fa-light fa-trash-can" style={{ width: '18px', textAlign: 'center' }}></i>}
                                >
                                    Löschen
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                );
            default:
                return cellValue;
        }
    }, []);

    const onRowsPerPageChange = useCallback((e) => {
        setRowsPerPage(Number(e.target.value));
        setPage(1);
    }, []);
    const onSearchChange = React.useCallback((value) => {
        if (value) {
            setFilterValue(value);
            setPage(1);
        } else {
            setFilterValue("");
        }
    }, []);

    const onClear = React.useCallback(() => {
        setFilterValue("")
        setPage(1)
    }, [])

    const topContent = useMemo(() => {
        return (
            <div className="flex flex-col gap-4">
                <div className="flex justify-between gap-3 items-end">
                    <Input
                        variant="bordered"
                        isClearable
                        className="w-full sm:max-w-[44%]"
                        placeholder="Suche nach Namen..."
                        startContent={<i className="fa-light fa-magnifying-glass" style={{ width: '18px', textAlign: 'center' }}></i>}
                        value={filterValue}
                        onClear={() => onClear()}
                        onValueChange={onSearchChange}
                    />
                    <div className="flex gap-3">
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<i className="fa-light fa-chevron-down text-small" style={{ textAlign: 'center' }}></i>} variant="flat">
                                    Status
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={statusFilter}
                                selectionMode="multiple"
                                onSelectionChange={setStatusFilter}
                            >
                                {statusOptions.map((status) => (
                                    <DropdownItem key={status.uid}>
                                        {status.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown>
                            <DropdownTrigger className="hidden sm:flex">
                                <Button endContent={<i className="fa-light fa-chevron-down text-small" style={{ textAlign: 'center' }}></i>} variant="flat">
                                    Felder
                                </Button>
                            </DropdownTrigger>
                            <DropdownMenu
                                disallowEmptySelection
                                aria-label="Table Columns"
                                closeOnSelect={false}
                                selectedKeys={visibleColumns}
                                selectionMode="multiple"
                                onSelectionChange={setVisibleColumns}
                            >
                                {columns.map((column) => (
                                    <DropdownItem key={column.uid}>
                                        {column.name}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                <div className="flex justify-between items-center pt-4">
                    <span className="text-default-400 text-small">Insgesamt {users.length} Nutzer</span>
                    <label className="flex items-center text-default-400 text-small">
                        Zeilen pro Seite:
                        <select
                            className="bg-transparent outline-none text-default-400 text-small"
                            onChange={onRowsPerPageChange}
                        >
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </label>
                </div>
            </div>
        );
    }, [
        filterValue,
        statusFilter,
        visibleColumns,
        onRowsPerPageChange,
        users.length,
        onSearchChange,
        onClear,
    ]);

    const bottomContent = useMemo(() => {
        return (
            <div className="py-2 px-2 flex justify-between items-center">
                <Pagination
                    isCompact
                    showControls
                    showShadow
                    color="primary"
                    page={page}
                    total={pages}
                    onChange={setPage}
                />
            </div>
        );
    }, [page, pages]);

    return (
        <div className="w-full flex-1">
            <div className="flex items-center gap-x-3">
                <h1 className="text-3xl font-bold leading-9 text-default-foreground">Nutzerverwaltung</h1>
            </div>
            <h2 className="mt-2 text-small text-default-500 mb-6">
                Verwalte alle Appnutzer.
            </h2>
            {loading ? (
                <Spinner />
            ) : (
                <Table
                    aria-label="App Users Table"
                    isHeaderSticky
                    bottomContent={bottomContent}
                    bottomContentPlacement="outside"
                    selectedKeys={selectedKeys}
                    selectionMode="multiple"
                    sortDescriptor={sortDescriptor}
                    removeWrapper
                    topContent={topContent}
                    topContentPlacement="outside"
                    onSelectionChange={setSelectedKeys}
                    onSortChange={setSortDescriptor}
                >
                    <TableHeader columns={headerColumns}>
                        {(column) => (
                            <TableColumn
                                key={column.uid}
                                align={column.uid === "actions" ? "center" : "start"}
                                allowsSorting={column.sortable}
                            >
                                {column.name}
                            </TableColumn>
                        )}
                    </TableHeader>
                    <TableBody emptyContent={"Keine Nutzer gefunden"} items={sortedItems}>
                        {(item) => (
                            <TableRow key={item.id}>
                                {(columnKey) => <TableCell>{renderCell(item, columnKey)}</TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )}
        </div>
    );
};

export default Users;

const columns = [
    { name: "ID", uid: "id", },
    { name: "Username", uid: "username", sortable: true },
    { name: "Alter", uid: "age", sortable: true },
    { name: "Geschlecht", uid: "gender", },
    { name: "Erstellt", uid: "created", sortable: true },
    { name: "Optionen", uid: "actions" },
];

const statusOptions = [
    { name: "Aktiv", uid: "aktiv" },
    { name: "Deaktiviert", uid: "deaktiviert" },
];

export { columns, statusOptions };
