import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, User, Modal, Button, ModalContent, ModalHeader, ModalBody, ModalFooter } from '@nextui-org/react';
import useToast from '../../hooks/useToast';
import mediacenterController from '../../api/mediacenterController';
import { formatBytes } from '../../helpers/fileFormatters';
import { Icon } from '@iconify/react/dist/iconify.js';

interface FileComponentProps {
    name: string;
    url: string;
    metadata: {
        size: number;
        lastModified: number;
    };
    refreshData: () => void;
}

const FileComponent: React.FC<FileComponentProps> = ({ name, url, metadata, refreshData }) => {
    const { showToast } = useToast();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url).then(() => {
            showToast("URL in Zwischenablage gesichert", 'success');
        }).catch((err) => {
            console.error('Error copying URL to clipboard:', err);
        });
    };

    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const deleteFile = async () => {
        const filePath = url.split('/').slice(-2).join('/');
        const success = await mediacenterController.deleteFile(filePath);
        if (success) {
            showToast("Datei gelöscht", 'success');
            refreshData();
        } else {
            showToast("Fehler beim Löschen der Datei", 'error');
        }
        setIsDeleteModalOpen(false);
    };

    return (
        <>
            <Dropdown placement="bottom-end">
                <DropdownTrigger>
                    <User
                        name={name}
                        description={`Datei • ${formatBytes(metadata.size)} • ${formatBytes(metadata.lastModified)}`}
                        avatarProps={{
                            src: "",
                            fallback: <Icon height={18} icon="solar:file-line-duotone" width={18} />
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                </DropdownTrigger>
                <DropdownMenu aria-label="File options" className="w-[250px]" itemClasses={{ base: "gap-4" }}>
                    <DropdownItem
                        onClick={copyToClipboard}
                        description="Zwischenablage"
                        startContent={<Icon height={18} icon="solar:copy-outline" width={18} />}
                    >
                        Pfad kopieren
                    </DropdownItem>
                    <DropdownItem
                        onClick={downloadFile}
                        description="Speichern"
                        startContent={<Icon height={18} icon="solar:download-minimalistic-outline" width={18} />}
                    >
                        Herunterladen
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => setIsDeleteModalOpen(true)}
                        description="Unwiderruflich"
                        startContent={<Icon height={18} icon="solar:trash-bin-minimalistic-outline" width={18} />}
                    >
                        Löschen
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <ModalContent>
                    <ModalHeader>Datei löschen?</ModalHeader>
                    <ModalBody>
                        <p>Bist Du Dir sicher, dass Du diese Datei unwiderruflich löschen möchtest?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onPress={() => setIsDeleteModalOpen(false)}>
                            Abbrechen
                        </Button>
                        <Button color="primary" onPress={deleteFile}>
                            Löschen
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FileComponent;
