"use client";

import React from "react";
import { Spacer } from "@nextui-org/react";
import { cn } from "@nextui-org/react";

const SupportConfig = React.forwardRef(({ className, ...props }, ref) => {

  return (
    <div ref={ref} className={cn("p-2", className)} {...props}>
      <div>
        <p className="text-base font-medium text-default-700">Supportinformationen</p>
        <p className="mt-1 text-sm font-normal text-default-400">
          Wann und über welche Kanäle steht der Support zur Verfügung?
        </p>
      </div>
    </div>
  );
});

SupportConfig.displayName = "SupportConfig";

export default SupportConfig;
