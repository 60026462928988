import CookieHelper from "../helpers/getCookieHelper";
import Filter from "../models/filter";

interface ApiResponse<T> {
  response: T | null;
  error: string | null;
}

const filterController = {
  getAllFilters: function (): Promise<ApiResponse<Filter[]>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}filters`, {
        method: 'GET',
        headers,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.response && Array.isArray(json.response)) {
            const filters = json.response.map((item: any) => new Filter(item));
            resolve({ response: filters, error: null });
          } else {
            resolve({ response: null, error: json.error || "Unexpected response format" });
          }
        })
        .catch((error) => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },

  createFilter: function (filterData: any): Promise<ApiResponse<boolean>> {
    return new Promise((resolve) => {
      const apiUrl = process.env.REACT_APP_API_BASE_URL;
      const bearerToken = process.env.REACT_APP_API_BEARER_TOKEN;
      const token = CookieHelper.get('happyActiveUser');

      if (!apiUrl || !bearerToken) {
        resolve({ response: null, error: "Missing API configuration." });
        return;
      }

      const headers: HeadersInit = {
        'Content-Type': 'application/json',
        ...(bearerToken ? { 'bearer': bearerToken } : {}),
        'usertoken': token || '',
      };

      fetch(`${apiUrl}filters`, {
        method: 'POST',
        headers,
        body: JSON.stringify(filterData),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.response === true) {
            resolve({ response: true, error: null });
          } else {
            resolve({ response: null, error: json.error || "Filter creation failed" });
          }
        })
        .catch((error) => {
          console.error(error);
          resolve({ response: null, error: error.toString() });
        });
    });
  },
};

export default filterController;