import React from 'react';
import { useLocation } from 'react-router-dom';
import FilterList from './FilterList';
import FilterEditor from './FilterEditor';
import FilterDetail from './FilterDetail';

const FilterPage = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');

  const path = location.pathname;

  if (path.startsWith('/filters/create')) {
    return <FilterEditor />;
  }

  if (path.startsWith('/filters/edit') && id) {
    return <FilterEditor id={id} />;
  }

  if (path.startsWith('/filters/') && id) {
    return <FilterDetail id={id} />;
  }

  return <FilterList />;
};

export default FilterPage;
