// @ts-nocheck

"use client";

import React, { useState, useEffect, KeyboardEvent } from 'react';
import { Button, Input, Checkbox, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, useDisclosure, Spacer } from "@nextui-org/react";
import { Icon } from "@iconify/react";
import { useNavigate } from 'react-router-dom';
import authController from "../api/authController";
import useToast from '../hooks/useToast';

const AuthPage: React.FC = () => {
    const navigate = useNavigate();
    const { showToast } = useToast();

    // STATE TYPES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaveEmail, setIsSaveEmail] = useState<boolean>(false);
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const { isOpen: isErrorModalOpen, onOpen: onErrorModalOpen, onClose: onErrorModalClose } = useDisclosure();

    // EFFECTS
    useEffect(() => {
        const rememberedEmail = authController.presetEmailIfRemembered();
        setEmail(rememberedEmail);
        if (rememberedEmail !== '' && rememberedEmail !== null) {
            setIsSaveEmail(true);
        }
    }, []);

    // FUNCTIONS
    const toggleVisibility = (): void => setIsVisible(!isVisible);

    const keyHandleLogin = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    const handleLogin = async (): Promise<void> => {
        setIsLoading(true);
        setEmailError(false);
        setPasswordError(false);

        if (!email.trim()) {
            setEmailError(true);
            setIsLoading(false);
            return;
        }
        if (!password.trim()) {
            setPasswordError(true);
            setIsLoading(false);
            return;
        }

        try {
            const { error } = await authController.loginUserApi(email, password, isSaveEmail);
            setIsLoading(false);
            if (error) {
                console.error('Login error:', error);
                setError(error);
                showToast("Login fehlgeschlagen! Prüfe Deinen Zugang oder Deine Berechtigungen!", 'error');
                onErrorModalOpen();
            } else {
                showToast("Login erfolgreich!", 'success');
                navigate('/');
            }
        } catch (err: any) {
            console.error('Unexpected error:', err);
            setIsLoading(false);
            setError(err.message);
            showToast("Unerwarteter Fehler aufgetreten!", 'error');
            onErrorModalOpen();
        }
    };

    return (
        <>
            <div className="relative flex h-screen w-screen">
                <div className="flex w-full items-center justify-center bg-background lg:w-1/2">
                    <div className="flex w-full max-w-sm flex-col items-center gap-4 p-4">
                        <div className="w-full text-left">
                            <p className="pb-2 text-xl font-medium">Hallo 👋</p>
                            <p className="text-small text-default-500">Schön, dass Du da bist. Logge Dich hier mit Deinem Verwaltungsaccount ein.</p>
                        </div>

                        <form className="flex w-full flex-col gap-3" onSubmit={(e) => e.preventDefault()}>
                            <Input
                                label="E-Mail / Benutzername"
                                value={email}
                                isInvalid={emailError}
                                name="email"
                                placeholder=""
                                type="text"
                                variant="bordered"
                                onChange={(e) => {
                                    setEmail(e.target.value.replace(/\s/g, ''));
                                    setEmailError(false);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === ' ' || e.key === 'Spacebar') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                            <Input
                                endContent={
                                    <button type="button" onClick={toggleVisibility}>
                                        {isVisible ? (
                                            <Icon
                                                className="pointer-events-none text-2xl text-default-400"
                                                icon="solar:eye-closed-linear"
                                            />
                                        ) : (
                                            <Icon
                                                className="pointer-events-none text-2xl text-default-400"
                                                icon="solar:eye-bold"
                                            />
                                        )}
                                    </button>
                                }
                                label="Passwort"
                                name="password"
                                placeholder=""
                                type={isVisible ? "text" : "password"}
                                isInvalid={passwordError}
                                variant="bordered"
                                onKeyUp={keyHandleLogin}
                                onChange={(e) => {
                                    setPassword(e.target.value.replace(/\s/g, ''));
                                    setPasswordError(false);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === ' ' || e.key === 'Spacebar') {
                                        e.preventDefault();
                                    }
                                }}
                            />

                            <div className="flex items-center justify-between px-1 py-2">
                                <Checkbox name="remember" size={'sm'} isSelected={isSaveEmail} onValueChange={setIsSaveEmail}>
                                    E-Mail / Benutzernamen speichern
                                </Checkbox>
                            </div>
                            <Button color="primary" onPress={handleLogin} isLoading={isLoading}>
                                Einloggen
                            </Button>
                        </form>
                    </div>
                </div>

                <div
                    className="relative hidden w-1/2 flex-col-reverse rounded-medium p-10 shadow-small lg:flex"
                    style={{
                        backgroundImage:
                            "url(https://images.pexels.com/photos/415314/pexels-photo-415314.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                </div>
            </div>
            <Modal backdrop="blur" isOpen={isErrorModalOpen} onClose={onErrorModalClose} placement="bottom-center">
                <ModalContent>
                    {() => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Login fehlgeschlagen</ModalHeader>
                            <ModalBody>
                                <p>
                                    Prüfe Deinen Zugang oder Deine Berechtigungen!
                                </p>
                                <Spacer y={4} />
                                <p>
                                    {error}
                                </p>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onPress={onErrorModalClose}>
                                    Alles klar
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default AuthPage;