interface CategoryParams {
    id: number;
    name: string;
    description: string;
    keywords?: string[];
    icon: string;
    activeOffers?: number;
    subcats?: Category[];
    parent_id?: number | null;
    deactivated?: boolean;
    createdAt: string;
    updatedAt: string;
  }
  
  class Category {
    id: number;
    name: string;
    description: string;
    keywords: string[];
    icon: string;
    activeOffers: number;
    subcats: Category[];
    parent_id: number | null;
    deactivated: boolean;
    createdAt: string;
    updatedAt: string;
  
    constructor({
      id,
      name,
      description,
      keywords = [],
      icon,
      activeOffers = 0,
      subcats = [],
      parent_id = null,
      deactivated = false,
      createdAt,
      updatedAt,
    }: CategoryParams) {
      this.id = id;
      this.name = name;
      this.description = description;
      this.keywords = keywords;
      this.icon = icon;
      this.activeOffers = activeOffers;
      this.subcats = subcats;
      this.parent_id = parent_id;
      this.deactivated = deactivated;
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
    }
  
    static fromJson(json: CategoryParams): Category {
      return new Category(json);
    }
  }
  
  export default Category;
  