import React, { useEffect, useState, useRef } from 'react';
import { Button, Spacer, Spinner, Input, useDisclosure, Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Progress } from '@nextui-org/react';
import mediacenterController from '../api/mediacenterController';
import FolderComponent from '../components/mediacenter/FolderComponent';
import ImageComponent from '../components/mediacenter/ImageComponent';
import FileComponent from '../components/mediacenter/FileComponent';
import Breadcrumb from '../components/mediacenter/Breadcrumb';
import { supabase } from '../api/supabaseClient';
import { useNavigate } from 'react-router-dom';

const Mediacenter = ({ folderPath }) => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [folderName, setFolderName] = useState('');
    const [creatingFolder, setCreatingFolder] = useState(false);
    const [currentPath, setCurrentPath] = useState(folderPath);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({});
    const [uploading, setUploading] = useState(false);
    const { isOpen, onOpen, onOpenChange } = useDisclosure();
    const { isOpen: isUploadModalOpen, onOpen: onUploadModalOpen, onOpenChange: onUploadModalOpenChange } = useDisclosure();
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const hasPromptedRef = useRef(false);

    useEffect(() => {
        const checkAndFetchData = async () => {
            try {
                setLoading(true);
                const exists = await mediacenterController.pathExists(currentPath);
                if (!exists && !hasPromptedRef.current) {
                    hasPromptedRef.current = true;
                    const create = window.confirm(`Der angegebene Pfad existiert nicht. Möchtest Du ihn erstellen?`);
                    if (create) {
                        const creationSuccess = await mediacenterController.createFolder(currentPath);
                        if (!creationSuccess) {
                            throw new Error('Folder creation failed');
                        }
                    } else {
                        setCurrentPath('ressources');
                        navigate('/mediacenter/ressources');
                        return;
                    }
                }
                const data = await mediacenterController.getStorageData(currentPath);
                setItems(data);
            } catch (error) {
                console.error('Error fetching data or creating folder:', error);
            } finally {
                setLoading(false);
            }
        };

        checkAndFetchData();
    }, [currentPath, navigate]);

    useEffect(() => {
        navigate(`/mediacenter/${currentPath}`);
    }, [currentPath, navigate]);

    const getFileUrl = (folderPath, name) => {
        const { data, error } = supabase.storage.from('happyshop').getPublicUrl(`${folderPath}/${name}`);
        if (error) {
            console.error(`Error getting public URL for file ${name} in folder ${folderPath}:`, error);
            return null;
        }
        return data.publicUrl;
    };

    const handleCreateFolder = async (onClose) => {
        if (folderName.trim() === '') return;
        setCreatingFolder(true);
        const success = await mediacenterController.createFolder(`${currentPath}/${folderName}`);
        if (success) {
            const data = await mediacenterController.getStorageData(currentPath);
            setItems(data);
            setFolderName('');
            onClose();
        }
        setCreatingFolder(false);
    };

    const handleFolderClick = (folderName) => {
        setCurrentPath(`${currentPath}/${folderName}`);
    };

    const handleNavigate = (path) => {
        setCurrentPath(path);
    };

    const handleFileChange = (e) => {
        setUploadFiles(Array.from(e.target.files));
    };

    const handleFileUpload = async (onClose) => {
        setUploading(true);
        setUploadProgress({});
        const success = await mediacenterController.uploadFiles(currentPath, uploadFiles, (progress, fileName) => {
            setUploadProgress(prevProgress => ({ ...prevProgress, [fileName]: progress }));
        });
        if (success) {
            const data = await mediacenterController.getStorageData(currentPath);
            setItems(data);
            onClose();
        }
        setUploading(false);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const files = Array.from(e.dataTransfer.files);
        setUploadFiles(files);
    };

    const refreshData = async () => {
        const data = await mediacenterController.getStorageData(currentPath);
        setItems(data);
    };

    return (
        <>
            <div className="w-full flex-1">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <div className="flex items-center gap-x-3">
                            <h1 className="text-3xl font-bold leading-9 text-default-foreground">Mediacenter</h1>
                        </div>
                        <h2 className="mt-2 text-small text-default-500 mb-6">
                            Verwalte App- & Webressourcen
                        </h2>
                    </div>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Button color="primary" variant='ghost' onPress={onOpen} auto>Neuen Ordner erstellen</Button>
                        <Button color="primary" onPress={onUploadModalOpen} auto>Dateien hochladen</Button>
                    </div>
                </div>
                <Breadcrumb path={currentPath} onNavigate={handleNavigate} />
                <Spacer y={4} />
                {loading ? (
                    <Spinner />
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        {items.length === 0 ? (
                            <p>Dieser Ordner ist leer.</p>
                        ) : (
                            items.map((item, index) => {
                                const fileExtension = item.name.split('.').pop().toLowerCase();
                                const url = item.type !== 'folder' ? getFileUrl(currentPath, item.name) : null;
                                return (
                                    <React.Fragment key={index}>
                                        {item.type === 'folder' ? (
                                            <FolderComponent name={item.name} onClick={() => handleFolderClick(item.name)} />
                                        ) : (
                                            ['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension) ? (
                                                <ImageComponent name={item.name} url={url} metadata={item.metadata} refreshData={refreshData} />
                                            ) : (
                                                <FileComponent name={item.name} url={url} metadata={item.metadata} refreshData={refreshData} />
                                            )
                                        )}
                                        {index < items.length - 1 && <Spacer y={2} />}
                                    </React.Fragment>
                                );
                            })
                        )}
                    </div>
                )}
            </div>
            <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Neuen Ordner erstellen</ModalHeader>
                            <ModalBody>
                                <Input
                                    variant="bordered"
                                    clearable
                                    fullWidth
                                    value={folderName}
                                    onChange={(e) => setFolderName(e.target.value)}
                                    placeholder="Ordnername"
                                />
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Abbrechen
                                </Button>
                                <Button color="primary" isLoading={creatingFolder} onPress={() => handleCreateFolder(onClose)}>
                                    Erstellen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            <Modal isOpen={isUploadModalOpen} onOpenChange={onUploadModalOpenChange}>
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1">Dateien hochladen</ModalHeader>
                            <ModalBody>
                                <div
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                    onClick={() => fileInputRef.current.click()}
                                    style={{
                                        border: '2px dashed #ccc',
                                        padding: '20px',
                                        textAlign: 'center',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {uploadFiles.length === 0 ? (
                                        'Dateien hierhin ziehen oder klicken, um Dateien auszuwählen'
                                    ) : (
                                        <ul>
                                            {uploadFiles.map((file, index) => (
                                                <li key={index}>{file.name}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                                <input
                                    type="file"
                                    multiple
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleFileChange}
                                />
                                {Object.keys(uploadProgress).map((fileName) => (
                                    <div key={fileName}>
                                        <p>{fileName}</p>
                                        <Progress
                                            aria-label="Uploading..."
                                            size="md"
                                            value={uploadProgress[fileName]}
                                            color="success"
                                            showValueLabel={true}
                                            className="max-w-md"
                                        />
                                    </div>
                                ))}
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>
                                    Abbrechen
                                </Button>
                                <Button color="primary" isLoading={uploading} onPress={() => handleFileUpload(onClose)}>
                                    Hochladen
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default Mediacenter;
